import { QuestionSectionType, QuestionType } from "../enums";
import { Question } from "./question";

export interface ISubSection {
    subSectionId: string;
    subSectionTitle: string;
    containDefect: boolean;
    questions: Question[];
    validated: boolean;
}

export class SubSection implements ISubSection {
    subSectionId: string;
    subSectionTitle: string;
    containDefect: boolean;
    questions: Question[];
    validated: boolean;
    constructor(
        subSectionId: string,
        containDefect: boolean,
    ) {
        this.subSectionId = subSectionId;
        this.containDefect = containDefect;
        this.questions = [];
        this.validated = false;
        this.subSectionTitle = ""
    }
}
export function CreateSubSection() {
    const subSection = new SubSection('', false);
    subSection.questions.push(new Question("", '', '', false, '', '', QuestionType.Regular, false, '', QuestionSectionType.Tractor, '', false, '', '', '', '', '', false, '', ''));
    return subSection;
}