import { CreateEmptyFile, File } from "./question";

export interface IAttachment {
    attachment: File;
    fileName: string;
    attachmentBase64: string;
}

export class Attachment implements IAttachment {
    attachment: File;
    fileName: string;
    attachmentBase64: string;
    constructor(
        fileName: string
    ) {
        this.attachment = CreateEmptyFile();
        this.fileName = fileName;
        this.attachmentBase64 = "";
    }
}

export const CreateAttachments = () => {
    return [new Attachment(''), new Attachment(''), new Attachment('')];
}