





































































import { PageName } from "@/models/enums";
import { StoreAction, StoreGetter } from "@/store/store-mapping";
import { CreateNewUUID, Text } from "@/utility";
import Vue from "vue";
import { mapGetters } from "vuex";
import FormInput from "@/components/FormInput.vue";
import { QuestionFilter } from "@/models/question-section";
import { GoOverview, IsLocalMode } from "@/utility/ui-utility";
import FormTextarea from "@/components/FormTextarea.vue";

import config from "@/config/config.json";

export default Vue.extend({
  components: { FormInput, FormTextarea },
  data() {
    return {
      errorMsg: "",
      itemIndex: 1,
      disable: true,
    };
  },
  computed: {
    ...mapGetters({
      notifications: StoreGetter.emMaintainNotifications,
    }),
  },
  methods: {
    Text,
    CreateNewUUID,
    IsLocalMode,
    onNext() {
      this.errorMsg = "";
      GoOverview(this.$router);
    },
  },
  mounted(): void {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  },
});
