



































import Vue from "vue";
// in full builds helpers are exposed as Vuex.mapState
import { mapState } from "vuex";
import RecordQuestionSummaryRegular from "./RecordQuestionSummaryRegular.vue";
import RecordQuestionSummaryAdditional from "./RecordQuestionSummaryAdditional.vue";
import { ConsoleLog, CloneObj } from "@/utility";
// import HomePage from "../components/HomePage.vue";

export default Vue.extend({
  components: {
    // RecordQuestionHead,
    // RecordQuestionRegular,
    // RecordQuestionAdditional,
    RecordQuestionSummaryRegular,
    RecordQuestionSummaryAdditional,
    // HomePage,
  },
  data() {
    return {};
  },
  props: {
    recordQuestionSummaryData: {
      type: Object,
    },
  },
  computed: {},
  methods: {
    submitOrder() {
      this.$router.push({ name: "thankyou" });
    },
  },
  mounted() {
    ConsoleLog([
      "RecordQuestionSummaryItem.recordQuestionSummaryData",
      CloneObj(this.recordQuestionSummaryData),
    ]);
  },
});
