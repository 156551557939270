















































































import { ConsoleLog, Timeout, Text, IsNullOrEmpty } from "@/utility";
import Vue from "vue";
import { QrcodeStream } from "vue-qrcode-reader";

export default Vue.extend({
  components: { QrcodeStream },
  props: {
    formId: String,
    labelText: String,
    initialValue: String,
    handleChange: { type: Function },
  },
  data() {
    return {
      showDialog: false,
      cameraError: "",
      decodedContent: "",
      isValid: { type: Boolean, default: undefined },
      camera: "auto",
    };
  },
  computed: {
    validationSuccess(): boolean {
      return (this as any).isValid === true;
    },
    validationFailure(): boolean {
      return (this as any).isValid === false;
    },
    validationPending(): boolean {
      return (this as any).isValid === undefined && (this as any).camera === "off";
    },
  },
  methods: {
    Text,
    resetValidationState(): void {
      const self = this as any;
      self.isValid = undefined;
      self.message = "";
    },
    retry(): void {
      const self = this as any;
      self.resetValidationState();
      self.turnCameraOn();
    },
    submit(): void {
      const self = this as any;
      self.turnCameraOff();
      this.$emit("handleChange", self.decodedContent);
      self.showDialog = false;
    },
    onChanging(value1: string) {
      ConsoleLog({ onChanging: value1 });
      this.$emit("input", value1);
    },
    turnCameraOn() {
      (this as any).camera = "auto";
    },
    turnCameraOff() {
      (this as any).camera = "off";
    },
    async onDecode(scannedValue: string) {
      const self = this as any;
      self.decodedContent = scannedValue.toUpperCase();

      await Timeout(1000);
      self.isValid = true;
      this.submit();
      // self.turnCameraOff();
      // self.isValid = scannedValue.startsWith("http");
    },
    async onInit(promise: any) {
      // promise.then(console.log).catch(console.error);
      try {
        await promise;
        // promise.then(console.log);
      } catch (error: any) {
        if (error.name === "NotAllowedError") {
          this.cameraError = Text(
            "ml_114670",
            "ERROR: you need to grant camera access permission"
          );
        } else if (error.name === "NotFoundError") {
          this.cameraError = Text("ml_114671", "ERROR: no camera on this device");
        } else if (error.name === "NotSupportedError") {
          this.cameraError = "ERROR: secure context required (HTTPS, localhost)";
        } else if (error.name === "NotReadableError") {
          this.cameraError = Text("ml_114672", "ERROR: is the camera already in use?");
        } else if (error.name === "OverconstrainedError") {
          this.cameraError = Text(
            "ml_114673",
            "ERROR: installed cameras are not suitable"
          );
        } else if (error.name === "StreamApiNotSupportedError") {
          this.cameraError = Text(
            "ml_114674",
            "ERROR: Stream API is not supported in this browser"
          );
        } else if (error.name === "InsecureContextError") {
          this.cameraError = Text(
            "ml_114675",
            "ERROR: Camera access is only permitted in secure context. Use HTTPS or localhost rather than HTTP."
          );
        } else {
          this.cameraError = `${Text(
            "ml_114676",
            "ERROR: Stream API is not supported in this browser"
          )} (${error.name})`;
        }
      }
    },
    close() {
      const self = this as any;
      self.turnCameraOff();
      self.showDialog = false;
    },
  },
  beforeMount() {
    ConsoleLog({ QrScanerbeforeMount: this.initialValue });
    if (!IsNullOrEmpty(this.initialValue)) {
      this.decodedContent = this.initialValue;
    } else {
      this.decodedContent = "";
    }
  },
});
