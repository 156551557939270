import { QuestionSectionType, QuestionType } from "../enums";
import { Attachment, CreateAttachments } from "./attachment";

export interface IQuestion {
    sequenceId: string;
    desc: string;
    answer: string; // Good,Defective and N/A
    notificationIsNeeded: boolean;
    comment: string;
    // attachment: File;
    // fileName: string;
    // attachmentBase64: string;
    attachments: Attachment[];
    instruction: string;
    questionType: QuestionType;
    issueDesc: string;
    isReqular: boolean;
    subSectionId: string;
    sectionType: QuestionSectionType;
    numberRange: string;
    questionTypeBooleanValue: boolean;
    equipmentID: string;
    vehicleType:string;
    codeGroup: string;
    code: string;
    charact: string;
    hardStop: boolean;
    originalSequenceId: string;
    originalSubSectionId: string;
}

export class Question implements IQuestion {
    sequenceId: string;
    desc: string;
    answer: string;
    notificationIsNeeded: boolean;
    comment: string;
    // attachment: File;
    // fileName: string;
    // attachmentBase64: string;
    attachments: Attachment[];
    instruction: string;
    questionType: QuestionType;
    issueDesc: string;
    isReqular: boolean;
    subSectionId: string;
    sectionType: QuestionSectionType;
    numberRange: string;
    questionTypeBooleanValue: boolean;
    equipmentID: string;
    vehicleType:string;
    codeGroup: string;
    code: string;
    charact: string;
    hardStop: boolean;
    originalSequenceId: string;
    originalSubSectionId: string;
    constructor(
        sequenceId: string,
        desc: string,
        answer: string,
        notificationIsNeeded: boolean,
        comment: string,
        // fileName: string,
        instruction: string,
        questionType: QuestionType,
        isReqular: boolean,
        subSectionId: string,
        sectionType: QuestionSectionType,
        numberRange: string,
        questionTypeBooleanValue: boolean,
        equipmentID: string,
        vehicleType:string,
        codeGroup: string,
        code: string,
        charact: string,
        hardStop: boolean,
        originalSequenceId: string,
        originalSubSectionId: string,
    ) {
        this.sequenceId = sequenceId;
        this.originalSequenceId = originalSequenceId;
        this.desc = desc;
        this.answer = answer;
        this.notificationIsNeeded = notificationIsNeeded;
        this.comment = comment;
        // this.attachment = CreateEmptyFile();
        // this.fileName = fileName;
        // this.attachmentBase64 = "";
        this.attachments = CreateAttachments();
        this.instruction = instruction;
        this.questionType = questionType;
        this.issueDesc = "";
        this.isReqular = isReqular;
        this.subSectionId = subSectionId;
        this.originalSubSectionId = originalSubSectionId;
        this.sectionType = sectionType;
        this.numberRange = numberRange;
        this.questionTypeBooleanValue = questionTypeBooleanValue;
        this.equipmentID = equipmentID;
        this.vehicleType=vehicleType;
        this.codeGroup = codeGroup;
        this.code = code;
        this.charact = charact
        this.hardStop = hardStop
    }
}


export class File {
    name: string;
    size: number;
    type: string;
    lastModified: number;
    // lastModifiedDate: Date;

    constructor(
        name: string,
        size: number,
        type: string,
        lastModified: number,
        // lastModifiedDate: Date,

    ) {
        this.name = name;
        this.size = size;
        this.type = type;
        this.lastModified = lastModified;
        // this.lastModifiedDate = lastModifiedDate;
    }
}

export const CreateEmptyFile = () => {
    return new File("", 0, "", 0);
}