































































































import { PageName, PicType, QuestionType, RegularAnswer } from "@/models/enums";
import { CloneObj, ConsoleLog, IsNullOrEmpty, Timeout } from "@/utility";
import Vue from "vue";
import FormSelect from "@/components/FormSelect.vue";
import FormInput from "@/components/FormInput.vue";
import * as consts from "@/models/consts";
import { StoreMutation } from "@/store/store-mapping";
import { Question } from "@/models/question-section/question";
import FormTextarea from "./FormTextarea.vue";
import { ClearQuestionAnswer } from "@/services/data-process/questions-process";
import {
  GoDefect,
  GoGood,
  GetTranslationRequired,
  RegularItems,
} from "@/utility/ui-utility";
import FormRegular from "@/components/FormRegular.vue";

export default Vue.extend({
  components: { FormInput, FormSelect, FormTextarea, FormRegular },
  computed: {},
  props: {
    recordQuestionAdditionalData: {
      type: Object,
    },
  },
  data: () => ({
    QuestionType,
    rules: {
      inputString: [(v: string) => !!v || GetTranslationRequired()],
      inputNumber: [(v: string) => !!v || GetTranslationRequired()],
      InputSelect: [(v: any) => !!v || GetTranslationRequired()],
    },
    STR_SUBSECTION_ID: consts.STR_SUBSECTION_ID,
    STR_ADDITION_ID: consts.STR_ADDITION_ID,
  }),
  methods: {
    RegularItems,
    IsNullOrEmpty,
    onUpdateCharQuestion(question: Question): void {
      ConsoleLog({
        onUpdateCharQuestion: CloneObj(question),
      });
      const self = this as any;
      self.$store.commit(StoreMutation.emUpdateQuestion, { question: question });
      ConsoleLog({
        "onUpdateCharQuestion is completed": CloneObj(question),
      });
    },
    onUpdateQuestion(newValue: string, question: Question): void {
      ConsoleLog([
        "onUpdateQuestion for additional question",
        newValue,
        CloneObj(question),
      ]);
      //need to reset the question in order to re-check if the new value is valid.
      const self = this as any;
      self.$store.commit(StoreMutation.emUpdateQuestion, { question: question });
      ConsoleLog([
        "onUpdateQuestion for additional question after update question",
        newValue,
        CloneObj(question),
      ]);
      if (question.notificationIsNeeded) {
        GoDefect(this.$router, {
          questionData: question,
          lastValue: newValue,
        });
      }
    },
    editClick(e: any, question: Question) {
      ConsoleLog({ HomeEditCick: e });
      GoDefect(this.$router, {
        questionData: question,
        lastValue: RegularAnswer.Defective,
      });
    },
    questionClick(e: any, question: Question) {
      ConsoleLog({ HomeQuestionCick: e });
      GoGood(this.$router, { questionData: question });
    },
    goToquestionRegularDetail(
      newValue: string,
      question: Question,
      originalValue,
      sequnceId: string
    ): void {
      ConsoleLog([
        "goToquestionRegularDetail for addional",
        newValue,
        CloneObj(question),
        this.$refs["regularQ" + sequnceId],
        originalValue,
        sequnceId,
      ]);
      const self = this as any;
      self.$store.commit(StoreMutation.emUpdateQuestion, { question: question });
      if (newValue === RegularAnswer.Good) {
        // GoGood(this.$router, { questionData: question });
        self.$refs["regularQ" + sequnceId][0].setLastValue(newValue);
      } else if (newValue === RegularAnswer.Defective) {
        GoDefect(this.$router, { questionData: question, lastValue: originalValue });
      } else if (newValue === RegularAnswer.NA) {
        self.$refs["regularQ" + sequnceId][0].setLastValue(newValue);
      }
    },
  },
});
