export async function GetCurrentUseCase({ dispatch, state }: any) {
    // const currentUseCase = state.usecaseModule.selectedUseCase;
    // if (currentUseCase === undefined || currentUseCase.id === undefined || currentUseCase.id === '') {
    //     await dispatch("usecaseModule/dataLoading");
    //     return state.usecaseModule.selectedUseCase.id;
    // }

    // return currentUseCase.id;
    return "";
}
