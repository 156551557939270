import { Commit, Dispatch } from 'vuex'
import { BaseDataLoadingAction } from '..'
import { ILastEvcr, LastEvcr } from '../../models/last-evcr'
import { ConsoleLog, HandleAPIException, Timeout, Text } from '@/utility'
import { CreateEvcr, IEvcr } from '@/models/evcr'
import { ITripInfo } from '@/models/trip-info'
import { GetLastEvcr } from '@/services/evcr-svc'
import { HandleSapReturnCode } from '@/utility/ui-utility'
import { Question } from '@/models/question-section/question'

interface IState {
  lastEvcr: IEvcr;
  questions: Question[];
  tractor: string;
  errorMsg: string;
}

export const lastEvcrModule = {
  namespaced: true,
  state: () => ({
    lastEvcr: CreateEvcr(),
    questions: [] as Question[],
    errorMsg: '',
    tractor: ""
  }),
  getters: {
    lastEvcrTrip: (state: IState) => {
      return state.lastEvcr.tripInfo;
    },
    getTractor: (state: IState) => {
      return state.tractor;
    },
    questions: (state: IState) => {
      return state.questions;
    },
  },
  mutations: {
    resetLastVcr: (state: IState) => {
      state.lastEvcr = CreateEvcr();
      state.questions = [];
      state.errorMsg = "";
      state.tractor = "";
    },
    updateTractor: (state: IState, { tractorData }: { tractorData: string }) => {
      state.tractor = tractorData;
    },
  },
  actions: {
    getLastEvcr: async (
      { state, commit }: { state: IState; commit: Commit },
      { trip }: { trip: ITripInfo }
    ) => {
      state.errorMsg = ''
      await BaseDataLoadingAction(commit, async () => {
        console.time("getLastEvcr");
        const res = await GetLastEvcr(trip)
        // const data = {} as ILastVcr;
        if (res.isSuccess === false) {
          const msg = HandleSapReturnCode(res.data?.error, Text('ml_114695', "Encountered error when getting the last eVCR,please try again."))
          ConsoleLog({ "getLastEvcr.res.isSuccess === false": res.data });
          HandleAPIException(res.data, state, msg);
          return;
        } else {
          ConsoleLog({ "getLastEvcr": res.data });
          state.lastEvcr.tripInfo = res.data.tripInfo;
          state.questions = res.data.questions;
          // state.questionChassis = res.data.chassis;
          // state.questionVessel = res.data.vessel;
          if (res.data.questions.length === 0) {
            state.errorMsg = Text('ml_114928', "There is no last eVCR for the selected Tractor.")
          }
        }
        // state.errorMsg = 'test error message for last eVCR '
        console.timeEnd("getLastEvcr");
      })
    },
  },
}
