import { Plant } from "@/models/plant";
import { ServiceCallResponse } from "@/models/service";
import { OrderedByPlantId } from "@/utility/sortHelper";


export function GeneratePlantList(data: ServiceCallResponse) {
    if (!data || !data.result || data.result.length === 0) {
        return [];
    }
    return data.result.map(
        (element: any) =>
            new Plant(
                element.PlantID ?? '',
                element.Name1 ?? '',
                element.Name2 ?? '',
                element.Country ?? '',
                element.City ?? '',
                element.ENCity ?? '',
                element.ENName1 ?? '',
                element.ENName2 ?? '',
            ),
    ).sort(OrderedByPlantId);
}
