import { Commit, Dispatch } from "vuex";
import { BaseDataLoadingAction } from "..";
import { ChangingConfirm, ClearError, CloneObj, ConsoleLog, HandleAPIException, ObjectContainAllFields, ObjectContainAllFieldsForArray, Timeout, Text } from "@/utility";
import { CreateEvcr, Evcr, IEvcr } from "@/models/evcr";
import { GetNearByPlantList, GetPlantList } from "@/services/plant-svc";
import { StoreAction, StoreMutation } from "../store-mapping";
import defaultNewRecordData from "@/mockData/newRecordData";
import { CreateNotification, IMaintainNotification, INotificationFilter } from "@/models/maitain-notification";
import { GetFive, GetQuestions, GetVehicleList, PostEvcrImages, PostNewEvcr, PostNewEvcrSign, SetMaintainNotification } from "@/services/evcr-svc";
import { CreateQuestionAll, CreateQuestionChassis, CreateQuestionSection, CreateQuestionTractor, CreateQuestionVessel, IQuestionFilter, IQuestionSection, QuestionSection } from "@/models/question-section";
import { IQuestion } from "@/models/question-section/question";
import { ISubSection } from "@/models/question-section/sub-section";
import { ClearQuestionAnswer, EvcrHasDefect, GetQuestionSection, IsNumberGood, UpdateQuestionSectionHasDefect, UpdateValidatedByQuestion } from "@/services/data-process/questions-process";
import { ImgeApiMode, QuestionSectionType, QuestionType, RegularAnswer, TripType } from "@/models/enums";
import { CreatePlant, IPlant } from "@/models/plant";
import { CustomResponse, ServiceCallResponse } from "@/models/service";
import { GeneratePostEvcrData, GeneratePostEvcrDataSign, GeneratePostEvcrImageArray, GeneratePostEvcrImages } from "@/services/data-process/packing-process";
import { HandleSapReturnCode, IsLocalMode, PrePostItems, RemoveStorageEvcr, SetIsEditEvcr, TripTypetems } from "@/utility/ui-utility";
import { ValidateEvcr } from "@/services/data-process/basic-process";
import * as consts from "@/models/consts"
import { CreateTripInfo, ITripInfo, TripInfo } from "@/models/trip-info";
import { IEvcrPost } from "@/models/evcr-post";
import { IVehicle } from "@/models/vehicle";


interface IState {
  evcr: Evcr;
  errorMsg: string;
  nearbyPlant: IPlant[];
  vehicles: IVehicle[];
  maintainNotifications: IMaintainNotification[];
  questions: IQuestionSection;
  questionTractor: IQuestionSection; // this is not in use after the Change request on Feb 16th,2022
  questionChassis: IQuestionSection;
  questionVessel: IQuestionSection;
  isEdit: boolean;
  currentQuestionFilter: ITripInfo;

}

export const evcrModule = {
  namespaced: true,
  state: () => ({
    evcr: CreateEvcr(),
    nearbyPlant: [] as IPlant[],
    vehicles: [] as IVehicle[],
    maintainNotifications: [],
    questionTractor: CreateQuestionTractor(false),
    questionChassis: CreateQuestionChassis(false, false),
    questionVessel: CreateQuestionVessel(false, false),
    questions: CreateQuestionAll(false, false),
    isEdit: false,
    currentQuestionFilter: CreateTripInfo(),
  }),
  getters: {
    evcr: (state: IState) => {
      return state.evcr;
    },
    nearbyPlant: (state: IState) => {
      return state.nearbyPlant;
    },
    vehicles: (state: IState) => (category: string) => {
      return state.vehicles.filter(item => item.category === category || item.category2 === category);
    },

    tripInfo: (state: IState) => {
      return state.evcr.tripInfo;
    },
    maintainNotifications: (state: IState) => {
      return state.maintainNotifications;
    },
    questions: (state: IState) => {
      return state.questions;
    },
    questionTractor: (state: IState) => {
      return state.questionTractor;
    },
    questionChassis: (state: IState) => {
      return state.questionChassis;
    },
    questionVessel: (state: IState) => {
      return state.questionVessel;
    },
    isEdit: (state: IState) => {
      return state.isEdit;
    },
    questionFilterChanged: (state: IState) => {
      let isQuestionFilterChanged = false;

      // return state.currentQuestionFilter.tractor !== state.evcr.tripInfo.tractor ||
      //   state.currentQuestionFilter.chassis !== state.evcr.tripInfo.chassis ||
      //   state.currentQuestionFilter.vessel !== state.evcr.tripInfo.vessel;

      if(state.currentQuestionFilter.isTractorThirdParty !== state.evcr.tripInfo.isTractorThirdParty){
        isQuestionFilterChanged = true;
      }

      if(state.currentQuestionFilter.isChassisThirdParty !== state.evcr.tripInfo.isChassisThirdParty){
        isQuestionFilterChanged = true;
      }

      if(state.currentQuestionFilter.isVesselThirdParty !== state.evcr.tripInfo.isVesselThirdParty){
        isQuestionFilterChanged = true;
      }

      if(state.currentQuestionFilter.tractor !== state.evcr.tripInfo.tractor){
        isQuestionFilterChanged = true;
      }

      if(state.currentQuestionFilter.chassis !== state.evcr.tripInfo.chassis){
        isQuestionFilterChanged = true;
      }

      if(state.currentQuestionFilter.vessel !== state.evcr.tripInfo.vessel){
        isQuestionFilterChanged = true;
      }

      if(state.evcr.tripInfo.isTractorThirdParty){
        if(state.currentQuestionFilter.tractorThirdPartyCategory != state.evcr.tripInfo.tractorThirdPartyCategory){
          isQuestionFilterChanged = true;
        }
      }

      if(state.evcr.tripInfo.isChassisThirdParty){
        if(state.currentQuestionFilter.chassisThirdPartyCategory != state.evcr.tripInfo.chassisThirdPartyCategory){
          isQuestionFilterChanged = true;
        }
      }

      if(state.evcr.tripInfo.isVesselThirdParty){
        if(state.currentQuestionFilter.vesselThirdPartyCategory != state.evcr.tripInfo.vesselThirdPartyCategory){
          isQuestionFilterChanged = true;
        }
      }

      return isQuestionFilterChanged;
    },
    hasDefects: (state: IState) => {
      const existing = true;
      if (state.questions.containDefect || state.questions.additionalQuestionContainDefect) {
        return existing;
      }
      if (state.questionTractor.containDefect || state.questionTractor.additionalQuestionContainDefect) {
        return existing;
      }
      if (state.questionTractor.isSelected && (state.questionChassis.containDefect || state.questionChassis.additionalQuestionContainDefect)) {
        return existing;
      }
      if (state.questionVessel.isSelected && (state.questionVessel.containDefect || state.questionVessel.additionalQuestionContainDefect)) {
        return existing;
      }
      return !existing;
      // existing = EvcrHasDefect(section);

    },
    hasTopDefects: (state: IState) => {
      return state.questions.containTopDefect;
    }
  },
  mutations: {
    resetEvcr: (state: IState) => {
      state.evcr = CreateEvcr();
      state.nearbyPlant = [];
      state.vehicles = [];
      state.errorMsg = "";
      state.maintainNotifications = [];
      state.questionTractor = CreateQuestionTractor(false);
      state.questionChassis = CreateQuestionChassis(false, false);
      state.questionVessel = CreateQuestionVessel(false, false);
      state.questions = CreateQuestionAll(false, false);
      state.isEdit = false;
      state.currentQuestionFilter = CreateTripInfo();
      // RemoveStorageEvcr();
    },
    clearNearByData: (state: IState) => {
      state.nearbyPlant = [];
    },
    clearVehicle: (state: IState) => {
      state.vehicles = [];
    },
    updateTripInfo: (state: IState, { tipInfo }: { tipInfo: TripInfo }) => {
      // ConsoleLog({ "mutations.updateTripInfo": CloneObj(tipInfo) });
      // state.evcr.tripInfo.lbsTripId = tipInfo.lbsTripId;
      // ConsoleLog({ "mutations.updateTripInfo end": CloneObj(state.evcr.tripInfo) });
    },
    clearTripId: (state: IState) => {
      if (state.evcr.tripInfo.tripType === TripType.LB) {
        state.evcr.tripInfo.lbsTripId = "";
      } else {
        state.evcr.tripInfo.pgTripId = "";
      }
      //state.evcr.tripInfo.lbsTripId = "";
      //state.evcr.tripInfo.pgTripId = "";
      //state.evcr.tripInfo.prePost = "";
      //state.evcr.tripInfo.customPlantId = "";
      ConsoleLog({ "mutations.clearTripId": CloneObj(state.evcr.tripInfo) });
    },
    updateIsEdit: (state: IState, { isEdit }: { isEdit: boolean }) => {
      state.isEdit = isEdit;
    },
    updateQuestion: (state: IState, { question }: { question: IQuestion }) => {
      ConsoleLog({ "updateQuestion": CloneObj(question) });
      //set the value of question.notificationIsNeeded
      switch (question.questionType) {
        case QuestionType.Regular:
        case QuestionType.Boolean:
          if (question.answer === RegularAnswer.Good) {
            // ClearQuestionAnswer(question);
            question.notificationIsNeeded = false;
          } else if (question.answer === RegularAnswer.Defective) {
            question.notificationIsNeeded = true;
          } else if (question.answer === RegularAnswer.NA) {
            // ClearQuestionAnswer(question);
            question.notificationIsNeeded = false;
          } else if (question.answer === "") {
            // ClearQuestionAnswer(question);
            question.notificationIsNeeded = false;
          }
          break;
        case QuestionType.Number:
          //   question.notificationIsNeeded = false;
          // if (IsNumberGood(question.answer, question.numberRange)) {
          //   // ClearQuestionAnswer(question);
          question.notificationIsNeeded = false;
          // } else {
          //   question.notificationIsNeeded = true;
          // }
          break;
        case QuestionType.Char:
          question.notificationIsNeeded = false;
          // ClearQuestionAnswer(question);
          break;
      }
      //update subSection.validated
      UpdateValidatedByQuestion(state.questions, question);
      ConsoleLog({ "updateQuestion end": question });
    },
    updateQuestions: (state: IState) => {
      UpdateQuestionSectionHasDefect(state.questions);
    },
    updateQuestionTractor: (state: IState) => {
      const section = GetQuestionSection(QuestionSectionType.Tractor, state);
      UpdateQuestionSectionHasDefect(section);
    },
    updateQuestionChassis: (state: IState) => {
      const section = GetQuestionSection(QuestionSectionType.Chassis, state);
      UpdateQuestionSectionHasDefect(section);
    },
    updateQuestionVessel: (state: IState) => {
      const section = GetQuestionSection(QuestionSectionType.Vessel, state);
      UpdateQuestionSectionHasDefect(section);
    },
    initializeState: (state: IState, { tmpEvcState }: { tmpEvcState: any }) => {
      ConsoleLog({ "initializeState": CloneObj(tmpEvcState) });
      state.errorMsg = "";
      //if the object in storage doesn't contain any fileds in the specified calss, then we could not use it .
      const validEvcr = ObjectContainAllFields(tmpEvcState.evcr, CreateEvcr());
      const validQuestions = ObjectContainAllFields(tmpEvcState.questions, CreateQuestionSection())
      // const validTractor = ObjectContainAllFields(tmpEvcState.questionTractor, CreateQuestionSection())
      // const validChassis = ObjectContainAllFields(tmpEvcState.questionChassis, CreateQuestionSection())
      // const validVessel = ObjectContainAllFields(tmpEvcState.questionVessel, CreateQuestionSection())

      // if (validEvcr && validTractor && validChassis && validVessel) {
      if (validEvcr && validQuestions) {
        state.evcr = tmpEvcState.evcr as IEvcr;
        // state.questionTractor = tmpEvcState.questionTractor as IQuestionSection;
        // state.questionChassis = tmpEvcState.questionChassis as IQuestionSection;
        // state.questionVessel = tmpEvcState.questionVessel as IQuestionSection;
        state.questions = tmpEvcState.questions as IQuestionSection;
        state.isEdit = true;
        state.currentQuestionFilter = tmpEvcState.currentQuestionFilter as ITripInfo;
      } else {
        throw Error("Storage data is invalid.");
      }
      ConsoleLog("initializeState end");
    },
    submitFailed: (state: IState, { error }: { error: string | object }) => {
      state.errorMsg = error ? error.toString() : "Unknown error";
    },
  },
  actions: {
    initialTripData: async (
      {
        state,
        commit,
        dispatch,
      }: { state: IState; commit: Commit; dispatch: Dispatch },
      {
        countryCode,
      }: { countryCode: string }
    ) => {
      ClearError(state);
      let needContinue = true;
      await BaseDataLoadingAction(commit, async () => {
        console.time('initialTripData');
        //const resulsts = await Promise.allSettled([
        //  // dispatch(StoreAction.emGetPlant, { countryCode: countryCode }, { root: true }),
        //  dispatch(StoreAction.emGetVehicle, { countryCode: countryCode }, { root: true }),
        //]);
        let resulsts ;
        if(countryCode === 'CN'){
          resulsts = await Promise.allSettled([
            dispatch(StoreAction.emGetPlant, { countryCode: countryCode }, { root: true }),
            dispatch(StoreAction.emGetVehicle, { countryCode: countryCode }, { root: true }),
          ])
        }
        else {
          resulsts = await Promise.allSettled([
            // dispatch(StoreAction.emGetPlant, { countryCode: countryCode }, { root: true }),
            dispatch(StoreAction.emGetVehicle, { countryCode: countryCode }, { root: true }),
          ])
        }
        resulsts.forEach((result) => {
          if (result.status !== "fulfilled") {
            commit(StoreMutation.amSubmitFailed, { error: result.reason }, { root: true });
            ConsoleLog([result.status, state.errorMsg, result.reason]);
            needContinue = false;
            return;
          }
        });
        if (!needContinue) {
          return;
        }
        // state.asset.lbShellKeys.length = 0;
        // state.isEditingAsset = true;
        console.timeEnd('initialTripData');
      });
    },
    initialData: async (
      {
        state,
        commit,
        dispatch,
      }: { state: IState; commit: Commit; dispatch: Dispatch },
      {
        longitude,
        latitude,
      }: { longitude: string; latitude: string; appName: string }
    ) => {
      ClearError(state);

      state.evcr.tripInfo.prePostItems = PrePostItems()
      state.evcr.tripInfo.tripTypeItems = TripTypetems()
      if (IsLocalMode()) {
        // state.evcr.tripInfo.driver1 = "ZHANGW13";
        // state.evcr.tripInfo.driver1Name = "Zhang Weida";
      }
    },
    getFive: async (
      {
        state,
        commit,
        dispatch,
      }: { state: IState; commit: Commit; dispatch: Dispatch },
      {
        longitude,
        latitude,
      }: { longitude: string; latitude: string; appName: string }
    ) => {
      ClearError(state);
      state.evcr.tripInfo.prePostItems = PrePostItems()
      await BaseDataLoadingAction(commit, async () => {
        const res = await GetFive()
        if (res.isSuccess === false) {
          ConsoleLog({ "GetFive.res.isSuccess === false": res.data });
          const msg = HandleSapReturnCode(res.data?.error, "Encountered error when getting Five");
          HandleAPIException(res.data, state, msg);
          return;
        } else {
          ConsoleLog({ "GetFive": res.data });
        }
      });
    },
    getNearbyPlant: async (
      {
        state,
        commit,
        dispatch,
      }: { state: IState; commit: Commit; dispatch: Dispatch },
      {
        longitude,
        latitude,
      }: { longitude: string; latitude: string; appName: string }
    ) => {
      ClearError(state);
      console.time("getNearbyPlant");
      state.nearbyPlant.length = 0;
      await BaseDataLoadingAction(commit, async () => {
        // state.nearbyPlant = await GetNearByPlantList(longitude, latitude);
        // const res = await GetNearByPlantList(longitude, latitude);
        // if (res.isSuccess === false) {
        //   ConsoleLog({ "getNearbyPlant.res.isSuccess === false": res.data });
        //   const msg = HandleSapReturnCode(res.data?.error, Text('ml_114696', "Encountered error when getting nearby plants,please try again."))
        //   HandleAPIException(res.data, state, msg);
        //   return;
        // } else {
        //   state.nearbyPlant = res.data;
        //   ConsoleLog({ "getNearbyPlant": res.data });
        // }

      });
    },
    getPlant: async (
      {
        state,
        commit,
        dispatch,
      }: { state: IState; commit: Commit; dispatch: Dispatch },
      {
        countryCode,
      }: { countryCode: string }
    ) => {
      ClearError(state);
      console.time("getPlant");
      state.nearbyPlant.length = 0;
      // await BaseDataLoadingAction(commit, async () => {
      const res = await GetPlantList(countryCode);;
      if (res.isSuccess === false) {
        ConsoleLog({ "getPlant.res.isSuccess === false": res.data });
        const msg = HandleSapReturnCode(res.data?.error, Text('ml_114696', "Encountered error when getting nearby plants,please try again."))
        // HandleAPIException(res.data, state, msg);
        console.timeEnd("getPlant");
        throw Error(msg);
        return;
      } else {
        state.nearbyPlant = res.data;
        ConsoleLog({ "getPlant": res.data });
      }
      // });
      console.timeEnd("getPlant");
    },
    getVehicle: async (
      {
        state,
        commit,
        dispatch,
      }: { state: IState; commit: Commit; dispatch: Dispatch },
      {
        countryCode,
      }: { countryCode: string }
    ) => {
      ClearError(state);
      console.time("getVehicle");
      state.vehicles.length = 0;
      const res = await GetVehicleList(countryCode);;
      if (res.isSuccess === false) {
        ConsoleLog({ "getVehicle.res.isSuccess === false": res.data });
        const msg = HandleSapReturnCode(res.data?.error, Text('ml_114900', "Encountered error when getting vehicles,please try again."))
        // HandleAPIException(res.data, state, msg);
        console.timeEnd("getVehicle");
        throw Error(msg);
        return;
      } else {
        state.vehicles = res.data;
        ConsoleLog({ "getVehicle": res.data });
      }
      console.timeEnd("getVehicle");
    },
    getMaintainNotification: async (
      {
        state,
        commit,
        dispatch,
      }: { state: IState; commit: Commit; dispatch: Dispatch },
      {
        filter,
      }: { filter: INotificationFilter }
    ) => {
      ClearError(state);
      await BaseDataLoadingAction(commit, async () => {
        console.time("getMaintainNotification");
        state.maintainNotifications.length = 0;
        const res = await await SetMaintainNotification(filter);
        if (res.isSuccess === false) {
          ConsoleLog({ "getMaintainNotification.res.isSuccess === false": res.data });
          const msg = HandleSapReturnCode(res.data?.error, Text('ml_114697', "Encountered error when getting the notification,please try again."))
          HandleAPIException(res.data, state, msg);
          return;
        } else {
          ConsoleLog({ "getMaintainNotification": res.data });
          if (res.data.length > 0) {
            res.data.forEach(element => {
              state.maintainNotifications.push(element);
            });
          }
        }
        console.timeEnd("getMaintainNotification");
      });
    },
    getQuestions: async (
      {
        state,
        commit,
        dispatch,
      }: { state: IState; commit: Commit; dispatch: Dispatch },
      {
        filter
      }: { filter: IQuestionFilter }
    ) => {
      ClearError(state);
      try {
        await BaseDataLoadingAction(commit, async () => {
          console.time("getQuestions");
          const res = await GetQuestions(filter);
          if (res.isSuccess === false) {
            const msg = HandleSapReturnCode(res.data?.error, Text('ml_114698', "Encountered error when getting the questions,please try again."))
            ConsoleLog({ "getQuestions.res.isSuccess === false": res.data });
            HandleAPIException(res.data, state, msg);
            //clear the current questions
            state.questions = {} as IQuestionSection;
          } else {
            ConsoleLog({ "getQuestions": res.data });
            state.questions = res.data.questions;
            // state.questionTractor = res.data.tractor;
            // state.questionChassis = res.data.chassis;
            // state.questionVessel = res.data.vessel;
          }
          // update currentQuestionFitler regardless of the questions API restult as we need to clear the current data if there is no questions for the current filter.
          state.currentQuestionFilter.tractor = state.evcr.tripInfo.tractor;
          state.currentQuestionFilter.chassis = state.evcr.tripInfo.chassis;
          state.currentQuestionFilter.vessel = state.evcr.tripInfo.vessel;

          state.currentQuestionFilter.isTractorThirdParty = state.evcr.tripInfo.isTractorThirdParty;
          state.currentQuestionFilter.isChassisThirdParty = state.evcr.tripInfo.isChassisThirdParty;
          state.currentQuestionFilter.isVesselThirdParty = state.evcr.tripInfo.isVesselThirdParty;

          state.currentQuestionFilter.tractorThirdPartyCategory = state.evcr.tripInfo.tractorThirdPartyCategory;
          state.currentQuestionFilter.chassisThirdPartyCategory = state.evcr.tripInfo.chassisThirdPartyCategory;
          state.currentQuestionFilter.vesselThirdPartyCategory = state.evcr.tripInfo.vesselThirdPartyCategory;

          console.timeEnd("getQuestions");

        });
      } catch (error: any) {
        commit(StoreMutation.EndLoading, null, { root: true });
        state.errorMsg = error.toString();
      }
    },
    postNewEvcr: async (
      {
        state,
        commit,
        dispatch,
      }: { state: IState; commit: Commit; dispatch: Dispatch },
      {
        imageApiMode,
        latitude,
      }: { imageApiMode: ImgeApiMode; latitude: string; appName: string }
    ) => {
      ClearError(state);
      let isOkay = false;
      try {
        await BaseDataLoadingAction(commit, async () => {
          console.time("postNewEvcr");
          // const post = GeneratePostEvcr(state.evcr.tripInfo, state.questionTractor, state.questionChassis, state.questionVessel)

          const postData = GeneratePostEvcrData(state.evcr.tripInfo, state.questions)
          let postImges = {} as IEvcrPost; //for sending all images at a time
          let postImgeArray: IEvcrPost[] = []; //for send one at a time.
          // state.hasDefects()
          const hasDefect = state.questions.containDefect || state.questions.additionalQuestionContainDefect;
          if (hasDefect) {
            switch (imageApiMode) {
              case ImgeApiMode.AllNotWait:
              case ImgeApiMode.AllWait:
                postImges = GeneratePostEvcrImages(state.evcr.tripInfo, state.questions)
                break;
              case ImgeApiMode.Parallel:
              case ImgeApiMode.One:
                GeneratePostEvcrImageArray(postImgeArray, state.evcr.tripInfo, state.questions)
                break;
            }
          }

          if (!ChangingConfirm(postData)) {
            // const responseData = { isSuccess: false, error: 'Error (5), please try again'};
            // HandleAPIException(responseData, state, responseData.error);
            return isOkay;
          }
          if (hasDefect) {
            switch (imageApiMode) {
              case ImgeApiMode.AllNotWait:
              case ImgeApiMode.AllWait:
                ConsoleLog({ 'postImges': postImges })
                if (!ChangingConfirm(postImges)) {
                  return isOkay;
                }
                break;
              case ImgeApiMode.Parallel:
              case ImgeApiMode.One:
                ConsoleLog({ 'postImgeArray': postImgeArray })
                if (!ChangingConfirm(postImgeArray)) {
                  return isOkay;
                }
                break;
            }
          }
          ConsoleLog({ 'postNewEvcr': postData })
          console.time("postNewEvcrDataOnly");
          //1> send basic data without images
          const res = await PostNewEvcr(postData)
          if (res.isSuccess === false) {
            console.timeEnd("postNewEvcrDataOnly");
            ConsoleLog({ "postNewEvcr.isSuccess === false": res.data.error });
            const msg = HandleSapReturnCode(res.data?.error, Text('ml_114699', "Encountered error when sumitting the eVCR"));
            HandleAPIException(res, state, msg);
          } else {
            isOkay = true;
            console.timeEnd("postNewEvcrDataOnly");
            ConsoleLog({ "postNewEvcr.res)": res.data });
            //2>Send images
            if (hasDefect) {
              switch (imageApiMode) {
                case ImgeApiMode.AllNotWait:
                  console.time("postNewEvcrAllImagesNotWait");
                  const resAll = PostEvcrImages(postImges)
                  console.timeEnd("postNewEvcrAllImagesNotWait");
                  break;
                case ImgeApiMode.AllWait:
                  console.time("postNewEvcrAllImagesWait");
                  const resAllWait = await PostEvcrImages(postImges)
                  console.timeEnd("postNewEvcrAllImagesWait");
                  break;
                case ImgeApiMode.Parallel:
                  console.time("postNewEvcrImageParallel");
                  try {
                    // let callArray: Promise<CustomResponse>[] = [];
                    // postImgeArray.forEach(imgPost => {
                    //   const promise1 = PostEvcrImages(imgPost)
                    //   callArray.push(promise1)
                    // });
                    // const resulsts = await Promise.allSettled([
                    //   ...callArray
                    // ]);
                    // resulsts.forEach((result: any) => {
                    //   if (result.status !== "fulfilled") {
                    //     ConsoleLog(["postNewEvcrImageParallel", result.status, state.errorMsg, result.reason]);
                    //   }
                    // });
                    await Promise.all(postImgeArray.map(async (filePost) => {
                      ConsoleLog({ "ImgeApiMode.Parallel": postImgeArray });
                      const paralle = await PostEvcrImages(filePost);
                    }));
                  } catch (error) {
                    ConsoleLog({ "postNewEvcrImageParallel.error": error });
                  }
                  console.timeEnd("postNewEvcrImageParallel");
                  break;
                case ImgeApiMode.One:
                  console.time("postNewEvcrImagesOneByOne");
                  try {
                    for (const imgPost of postImgeArray) {
                      const resOne = await PostEvcrImages(imgPost);
                    }
                  } catch (error) {
                    ConsoleLog({ "postNewEvcrImagesOneByOne.error": error });
                  }

                  console.timeEnd("postNewEvcrImagesOneByOne");
                  break;
              }
            }
          }
          console.timeEnd("postNewEvcr");
          return isOkay;
        });
      } catch (error: any) {
        commit(StoreMutation.EndLoading, null, { root: true });
        state.errorMsg = error.toString();
        alert(error);
      }
      return isOkay;
      // state.errorMsg = "test error";
    },
    postNewEvcrSign: async (
      {
        state,
        commit,
        dispatch,
      }: { state: IState; commit: Commit; dispatch: Dispatch },
      {
        imageApiMode,
        latitude,
        //signString,
      }: { imageApiMode: ImgeApiMode; latitude: string; 
        //signString: string; 
        appName: string }
    ) => {
      ClearError(state);
      let isOkay = false;
      try {
        await BaseDataLoadingAction(commit, async () => {
          console.time("postNewEvcr");
          // const post = GeneratePostEvcr(state.evcr.tripInfo, state.questionTractor, state.questionChassis, state.questionVessel)

          const postData = GeneratePostEvcrData(state.evcr.tripInfo, state.questions)
          let postImges = {} as IEvcrPost; //for sending all images at a time
          let postImgeArray: IEvcrPost[] = []; //for send one at a time.
          // state.hasDefects()
          const hasDefect = state.questions.containDefect || state.questions.additionalQuestionContainDefect;
          if (hasDefect) {	
            switch (imageApiMode) {	
              case ImgeApiMode.AllNotWait:	
              case ImgeApiMode.AllWait:	
                postImges = GeneratePostEvcrImages(state.evcr.tripInfo, state.questions)	
                break;	
              case ImgeApiMode.Parallel:	
              case ImgeApiMode.One:	
                GeneratePostEvcrImageArray(postImgeArray, state.evcr.tripInfo, state.questions)	
                break;	
            }	
          }
          if (!ChangingConfirm(postData)) {
            // const responseData = { isSuccess: false, error: 'Error (5), please try again'};
            // HandleAPIException(responseData, state, responseData.error);
            return isOkay;
          }
          if (hasDefect) {	
            switch (imageApiMode) {	
              case ImgeApiMode.AllNotWait:	
              case ImgeApiMode.AllWait:	
                ConsoleLog({ 'postImges': postImges })	
                if (!ChangingConfirm(postImges)) {	
                  return isOkay;	
                }	
                break;	
              case ImgeApiMode.Parallel:	
              case ImgeApiMode.One:	
                ConsoleLog({ 'postImgeArray': postImgeArray })	
                if (!ChangingConfirm(postImgeArray)) {	
                  return isOkay;	
                }	
                break;	
            }	
          }
          ConsoleLog({ 'postNewEvcr': postData })
          console.time("postNewEvcrDataOnly");
          //1> send basic data without images
          const res = await PostNewEvcr(postData)
          if (res.isSuccess === false) {
            console.timeEnd("postNewEvcrDataOnly");
            ConsoleLog({ "postNewEvcr.isSuccess === false": res.data.error });
            const msg = HandleSapReturnCode(res.data?.error, Text('ml_114699', "Encountered error when sumitting the eVCR"));
            HandleAPIException(res, state, msg);
          } else {
            isOkay = true;
            console.timeEnd("postNewEvcrDataOnly");
            ConsoleLog({ "postNewEvcr.res)": res.data });
            //2>Send images
            if (hasDefect) {
              switch (imageApiMode) {
                case ImgeApiMode.AllNotWait:
                  console.time("postNewEvcrAllImagesNotWait");
                  const resAll = PostEvcrImages(postImges)
                  console.timeEnd("postNewEvcrAllImagesNotWait");
                  break;
                case ImgeApiMode.AllWait:
                  console.time("postNewEvcrAllImagesWait");
                  const resAllWait = await PostEvcrImages(postImges)
                  console.timeEnd("postNewEvcrAllImagesWait");
                  break;
                case ImgeApiMode.Parallel:
                  console.time("postNewEvcrImageParallel");
                  try {
                    // let callArray: Promise<CustomResponse>[] = [];
                    // postImgeArray.forEach(imgPost => {
                    //   const promise1 = PostEvcrImages(imgPost)
                    //   callArray.push(promise1)
                    // });
                    // const resulsts = await Promise.allSettled([
                    //   ...callArray
                    // ]);
                    // resulsts.forEach((result: any) => {
                    //   if (result.status !== "fulfilled") {
                    //     ConsoleLog(["postNewEvcrImageParallel", result.status, state.errorMsg, result.reason]);
                    //   }
                    // });
                    await Promise.all(postImgeArray.map(async (filePost) => {
                      ConsoleLog({ "ImgeApiMode.Parallel": postImgeArray });
                      const paralle = await PostEvcrImages(filePost);
                    }));
                  } catch (error) {
                    ConsoleLog({ "postNewEvcrImageParallel.error": error });
                  }
                  console.timeEnd("postNewEvcrImageParallel");
                  break;
                case ImgeApiMode.One:
                  console.time("postNewEvcrImagesOneByOne");
                  try {
                    for (const imgPost of postImgeArray) {
                      const resOne = await PostEvcrImages(imgPost);
                    }
                  } catch (error) {
                    ConsoleLog({ "postNewEvcrImagesOneByOne.error": error });
                  }

                  console.timeEnd("postNewEvcrImagesOneByOne");
                  break;
              }
            }
          
            //3>Send sign
            console.time("postNewEvcrSign");
            //postData.eVCRRecordItemSet[0].Image=signString;
            //const resSign = await PostNewEvcrSign(postData)

            const postDataSign = GeneratePostEvcrDataSign(state.evcr.tripInfo, state.questions)
            ConsoleLog({ 'postNewEvcrSign': postDataSign })
            console.time("postNewEvcrDataSign");
            const resSign = await PostNewEvcrSign(postDataSign)
            if (resSign.isSuccess === false) {
              console.timeEnd("postNewEvcrDataSign");
              ConsoleLog({ "postNewEvcrSign.isSuccess === false": resSign.data.error });
            }else{
              console.timeEnd("postNewEvcrDataSign");
              ConsoleLog({ "postNewEvcrSign.resSign)": resSign.data });
            }
            console.timeEnd("postNewEvcrSign");
          }
          console.timeEnd("postNewEvcr");
          return isOkay;
        });
      } catch (error: any) {
        commit(StoreMutation.EndLoading, null, { root: true });
        state.errorMsg = error.toString();
        alert(error);
      }
      return isOkay;
      // state.errorMsg = "test error";
    },    
    validateNewEvcrSign: async (
      {
        state,
        commit,
        dispatch,
      }: { state: IState; commit: Commit; dispatch: Dispatch },
    ) => {
      ClearError(state);
      let isOkay = false;
      await BaseDataLoadingAction(commit, async () => {
        const validationMsg = await ValidateEvcr(state.evcr.tripInfo);
        if (validationMsg.length > 0) {
          state.errorMsg = validationMsg;
        } else {
          isOkay = true;
        }
      });
      // state.errorMsg = "test error";
      return isOkay;
    },
  },
};
