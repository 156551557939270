<template>
  <div id="ap-loading-overlay">
    <div id="ap-loading-image"></div>
  </div>
</template>
<script>
import Vue from "vue";

const Component = Vue.extend({
  name: "LoadingOverlay",
});

export default Component;
</script>
