



















import Vue from "vue";

export default Vue.extend({
  props: {
    value: String,
    label: String,
    rules: [],
    rows: String || Number,
    required: Boolean,
    readonly: { type: Boolean, default: false },
    showAsLabel: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
  },
  computed: {},
  methods: {
    onChanging(value: string) {
      this.$emit("input", value);
      this.$emit("userChange", value);
    },
    onUserChanging(value: any) {
      this.$emit("userChange", value);
    },
  },
});
