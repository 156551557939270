






































import { QuestionSection } from "@/models/question-section";
import { ConsoleLog, Timeout, Text } from "@/utility";
import Vue from "vue";
import { GetPicSize, VehiclePath } from "@/utility/ui-utility";

export default Vue.extend({
  components: {},
  props: {
    section: { type: Object, default: {} },
    // openDialog: { type: Boolean, default: false },
  },
  data() {
    return {
      showDialog: false,
      errorMsg: "",
      isValid: { type: Boolean, default: undefined },
      questions: { type: QuestionSection, default: {} },
      photoHeight: 0,
      photoWidth: 0,
    };
  },
  computed: {
    validationSuccess(): boolean {
      return (this as any).isValid === true;
    },
  },
  methods: {
    Text,
    updateShowDialog(status: boolean) {
      const self = this as any;
      self.showDialog = status;
    },

    VehiclePath,
    close(): void {
      (this as any).showDialog = false;
    },
    loadedPic(imageEvent: any) {
      // ConsoleLog({ loadPic: this.$refs.vehiclePic, imageEvent: imageEvent });
      const img = this.$refs.vehiclePic as any;
      if (img.image) {
        this.photoWidth = img.image.width;
        this.photoHeight = img.image.height;
        ConsoleLog({
          ZoomPhotoWidth: this.photoWidth,
          ZoomPhotoHeight: this.photoHeight,
        });
      }
    },
    picWidth() {
      const self = this as any;
      return GetPicSize(self.questions.imageName).width;
    },
    picHeight() {
      const self = this as any;
      return GetPicSize(self.questions.imageName).height;
    },
  },
  beforeMount() {
    const self = this as any;
    ConsoleLog({ "ZoomIn.questions": self.section });
    self.questions = self.section;
    // self.showDialog = self.openDialog;
  },
});
