










































import Vue from "vue";
// import image from '../assets/image/PictureTractor.jpg'; // with import
import FormSelect from "@/components/FormSelect.vue";
import { PageName, RegularAnswer } from "@/models/enums";
import * as consts from "@/models/consts";
import FormRegular from "@/components/FormRegular.vue";
import { CloneObj, ConsoleLog, IsNullOrEmpty } from "@/utility";
import { Question } from "@/models/question-section/question";
import { StoreMutation } from "@/store/store-mapping";
import {
  GoDefect,
  GoGood,
  GetTranslationRequired,
  RegularItems,
} from "@/utility/ui-utility";

export default Vue.extend({
  components: { FormRegular },
  computed: {},
  props: {
    recordQuestionRegularData: {
      type: Object,
    },
  },
  data: () => ({
    rules: {
      inputString: [(v: string) => !!v || GetTranslationRequired()],
      inputNumber: [(v: string) => !!v || GetTranslationRequired()],
      InputSelect: [(v: any) => !!v || GetTranslationRequired()],
    },
    STR_SUBSECTION_ID: consts.STR_SUBSECTION_ID,
  }),
  methods: {
    RegularItems,
    IsNullOrEmpty,
    editClick(e: any, question: Question) {
      ConsoleLog({ HomeEditCick: e });
      GoDefect(this.$router, {
        questionData: question,
        lastValue: RegularAnswer.Defective,
      });
    },
    questionClick(e: any, question: Question) {
      ConsoleLog({ HomeQuestionCick: e });
      GoGood(this.$router, { questionData: question });
    },
    goToquestionRegularDetail(newValue, question, originalValue, index: number): void {
      const self = this as any;
      // const firstSet = IsNullOrEmpty(originalValue) ? true : false;
      ConsoleLog([
        "goToquestionRegularDetail",
        newValue,
        CloneObj(question),
        self.$refs.regularQ,
        index,
      ]);

      self.$store.commit(StoreMutation.emUpdateQuestion, { question: question });
      if (newValue === RegularAnswer.Good) {
        self.$refs.regularQ[index].setLastValue(newValue);
        // GoGood(this.$router, { questionData: question });
      } else if (newValue === RegularAnswer.Defective) {
        GoDefect(this.$router, { questionData: question, lastValue: originalValue });
      } else if (newValue === RegularAnswer.NA) {
        self.$refs.regularQ[index].setLastValue(newValue);
      }
    },
  },
});
