

































































import { ImgeApiMode, LangCode, PageName, SubmitStatus } from "@/models/enums";
import { StoreAction, StoreGetter, StoreMutation } from "@/store/store-mapping";
import Vue from "vue";
import { mapGetters } from "vuex";
import {
  GoHome,
  GoTripInfo,
  GoCondition,
  GoQuestionTractor,
  GoBack,
  VehiclePath,
  GoSummary,
  Confirm,
  RemoveStorageEvcr,
} from "@/utility/ui-utility";
import { mapState } from "vuex";
import { QuestionFilter } from "@/models/question-section";
import {
  EvcrFormValidate,
  EvcrHasQuestion,
} from "@/services/data-process/questions-process";
import { ConsoleLog, Text } from "@/utility";
import SectionList from "@/components/SectionList.vue";

export default Vue.extend({
  components: {
    SectionList,
  },
  data() {
    return {
      errorMsg: "",
      submitMsg: "",
      LangCode:LangCode,
      Vue:Vue,
    };
  },
  computed: {
    ...mapGetters({
      notifications: StoreGetter.emMaintainNotifications,
      questions: StoreGetter.emQuestions,
      tripInfo: StoreGetter.emTripInfo,
      editable: StoreGetter.temEditable,
      hasDefects: StoreGetter.emHasDefects,
      questionFilterChanged: StoreGetter.emQuestionFilterChanged,
      userCountryCode: StoreGetter.umUserCountryCode,
    }),
  },
  methods: {
    GoHome,
    GoBack,
    GoTripInfo,
    GoCondition,
    GoQuestionTractor,
    VehiclePath,
    async goNext() {
      //Validate if all questions are completed.
      this.submitMsg = "";
      if (EvcrFormValidate(this.questions)) {
        this.$store.commit(StoreMutation.emUpdateQuestions);
        await this.confirmToSubmit();
      } else {
        this.submitMsg = Text("ml_114752", "Please complete eVCR form first.");
      }
    },
    async confirmToSubmit() {
      const self = this as any;
      if (this.hasDefects) {
        GoSummary(self.$router);
        return false;
      }
      if (!Confirm(Text("ml_114678", "Are you sure to submit the eVCR ?"))) {
        return false;
      }
      const isGood = await this.submit();
      if (isGood) {
        this.$router.push({
          name: PageName.RecordQuestionSubmitResult,
          params: { action: SubmitStatus.Success },
        });
      }
      return false;
    },
    async GoToSummary() {
      const self = this as any;
      this.errorMsg = "";
      if (EvcrFormValidate(this.questions)) {
        self.$store.commit(StoreMutation.emUpdateQuestions);
        GoSummary(self.$router);
      } else {
        this.errorMsg = Text("ml_114752", "Please answer all the questions first.");
      }
    },
    async submit() {
      //submit request
      const self = this as any;
      this.submitMsg = "";
      let isOk = false;
      ConsoleLog("eVCR sumbit at eVCR overview page");
      isOk = await self.$store.dispatch(StoreAction.emPostNewEvcr, {
        imageApiMode: ImgeApiMode.One,
        longitude: "2",
      });
      if (this.$store.state.evcrModule.errorMsg.length > 0) {
        this.submitMsg = this.$store.state.evcrModule.errorMsg;
      }
      if (isOk) {
        this.$store.commit(StoreMutation.emResetEvcr);
        RemoveStorageEvcr();
      }
      return isOk;
    },
    async loadQuestions() {
      this.errorMsg = "";
      const self = this as any;
      //await self.$store.dispatch(StoreAction.emGetQuestions, {
      //  filter: new QuestionFilter(
      //    this.tripInfo.tractor,
      //    this.tripInfo.thirdParty,
      //    this.tripInfo.chassis,
      //    this.tripInfo.vessel,
      //    "",
      //    this.userCountryCode
      //  ),
      //});
      await self.$store.dispatch(StoreAction.emGetQuestions, {
        filter: new QuestionFilter(
          this.tripInfo.isTractorThirdParty ? '' : this.tripInfo.tractor,
          this.tripInfo.thirdParty,
          this.tripInfo.isChassisThirdParty ? '' : this.tripInfo.chassis,
          this.tripInfo.isVesselThirdParty ? '' : this.tripInfo.vessel,
          this.tripInfo.isTractorThirdParty ? 'X' : '',
          this.tripInfo.tractorThirdPartyCategory,
          this.tripInfo.isChassisThirdParty ? 'X' : '',
          this.tripInfo.chassisThirdPartyCategory,
          this.tripInfo.isVesselThirdParty ? 'X' : '',
          this.tripInfo.vesselThirdPartyCategory,
          "",
          this.userCountryCode
        ),
      });
      if (this.$store.state.evcrModule.errorMsg.length > 0) {
        this.errorMsg = this.$store.state.evcrModule.errorMsg;
        if(this.errorMsg === "NO eVCR question exist." && this.tripInfo.language === "zh-CN"){
          this.errorMsg = Text("ml_116522", "NO eVCR question exist.");
        }
        return;
      }
      //update editable to true and therefore we no need to get the questions next time.
      this.$store.commit(StoreMutation.temSetEditable, { editable: true });
    },
  },
  async beforeMount() {
    ConsoleLog("RecordOverview.beforeMount");
    if (this.questionFilterChanged || !EvcrHasQuestion(this.questions)) {
      await this.loadQuestions();
      ConsoleLog("RecordOverview.loadQuestions");
    }
    // ConsoleLog(["beforeMount", (this as any).tripInfo]);
  },
  mounted() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  },
});
