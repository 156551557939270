













































































































































import Vue from "vue";
import {
  CloneObj,
  ConsoleLog,
  IsDebug,
  Timeout,
  validateOdometer,
  PadLeftZero,
  Text,
} from "@/utility";
import * as consts from "@/models/consts";
import { StoreAction, StoreGetter, StoreMutation } from "@/store/store-mapping";
import { mapGetters } from "vuex";
import { AddBase64Head, RemoveBase64Head } from "@/utility/ui-utility";

import FormInput from "@/components/FormInput.vue";
import { ISelectItem, SelectItem } from "@/models/select-item";
import FormRadioGroup from "@/components/FormRadioGroup.vue";
import FormSelect from "@/components/FormSelect.vue";
import {
  GoHome,
  GoQuestionTractor,
  GoTripInfo,
  GoCondition,
  GoOverview,
  GoBack,
  GoQuestionsView,
} from "@/utility/ui-utility";
import { IPlant } from "@/models/plant";
import { NotificationFilter } from "@/models/maitain-notification";
import { ValidateEvcr } from "@/services/data-process/basic-process";
import { TripType } from "@/models/enums";
export default Vue.extend({
  components: { FormInput, FormRadioGroup, FormSelect },
  data() {
    return {
      errorMsg: "",
      valid: false,
      tripIdLabel: Text("ml_114625", "LBShell Trip ID"),
    };
  },
  computed: {
    ...mapGetters({
      tripInfo: StoreGetter.lemLastEvcrTripInfo,
      questions: StoreGetter.lemQuestions,
    }),
    localDateTime(): { date: string; time: string; } {
      // const date3 = new Date('2023-06-20T09:21:00Z');
      // transformed by web UI code
      // this.tripInfo.date 2023-06-20
      // this.tripInfo.date 09:21
      ConsoleLog({ 'lastEvcr.date': this.tripInfo.date });
      ConsoleLog({ 'lastEvcr.time': this.tripInfo.time });
      const localDateTime = new Date( this.tripInfo.date + 'T' + this.tripInfo.time + ':00Z' );
      const localDateString = localDateTime.getFullYear() + '-' + PadLeftZero(localDateTime.getMonth() + 1) + '-' + PadLeftZero(localDateTime.getDate());
      const localTimeString = PadLeftZero(localDateTime.getHours()) + ':' + PadLeftZero(localDateTime.getMinutes());
      ConsoleLog({ 'lastEvcr.local.date': localDateString });
      ConsoleLog({ 'lastEvcr.local.time': localTimeString });
      return { date: localDateString, time: localTimeString };
    },    
  },
  methods: {
    Text,
    getPlantItems() {
      const items: ISelectItem[] = [];
      items.push(new SelectItem(`${this.tripInfo.plantId}`, this.tripInfo.plantId));
      return items;
    },
    GoBack,
    GoHome,
    IsDebug,
    AddBase64Head,
    async initialize() {
      // const self = this as any;
      // await self.$store.dispatch(StoreAction.emInitialData, {
      //   longitude: this.customerLocation.longitude,
      //   latitude: this.customerLocation.latitude,
      // });
      if (this.tripInfo.tripType === TripType.PG) {
        this.tripIdLabel = Text("ml_114902", "PG Trip ID");
      }
    },
    async onNext(): Promise<void> {
      this.errorMsg = "";
      const hasQuestions = this.questions.length > 0;
      if (hasQuestions) {
        GoQuestionsView(this.$router);
      }
    },
  },
  async created() {},
  async beforeMount() {
    await this.initialize();
  },
});
