



























































































import Vue from "vue";
import { mapGetters, mapState } from "vuex";
import RecordQuestionHead from "../components/RecordQuestionHead.vue";
import RecordQuestionRegular from "../components/RecordQuestionRegular.vue";
import RecordQuestionAdditional from "../components/RecordQuestionAdditional.vue";
import { StoreAction, StoreGetter, StoreMutation } from "@/store/store-mapping";
import {
  GoHome,
  GoBack,
  GoTripInfo,
  GoCondition,
  GoQuestionChassis,
  GoQuestionVessel,
  GoSummary,
  GoOverview,
  Confirm,
  RemoveStorageEvcr,
  GoQuestion,
} from "@/utility/ui-utility";

import { ImgeApiMode, PageName, QuestionSectionType, SubmitStatus } from "@/models/enums";
import { CloneObj, ConsoleLog, Text } from "@/utility";
import { IQuestionSection } from "@/models/question-section";
import { STR_ADDITION_ID } from "@/models/consts";
import {
  EvcrFormValidate,
  EvcrSectionValidate,
  GetLastRegualrSubSectionId,
  GetNextSubSectionId,
  GetPreviousSubSectionId,
  SetFirtLastSubsection,
} from "@/services/data-process/questions-process";
// import HomePage from "../components/HomePage.vue";

export default Vue.extend({
  components: {
    RecordQuestionHead,
    RecordQuestionRegular,
    RecordQuestionAdditional,
    // HomePage,
  },
  props: {
    subSectionId: String,
  },
  data() {
    return {
      errorMsg: "",
      valid: false,
      isRegular: true,
      notFirst: true,
      notLast: true,
    };
  },
  computed: {
    ...mapGetters({
      questions: StoreGetter.emQuestions,
      hasDefects: StoreGetter.emHasDefects,
    }),
    getData() {
      // ConsoleLog(["QueSection.vue.getData start", this.type]);
      let data: any;
      const section = this.questions as IQuestionSection;
      if (this.isRegular) {
        data = section.regularQuestionCollection.filter(
          (subSection) => subSection.subSectionId === this.subSectionId
        );
      } else {
        data = section.additionalQuestionCollection.filter(
          (subSection) => subSection.subSectionId === STR_ADDITION_ID
        );
      }
      ConsoleLog({
        "QueSection.getData end": CloneObj(data),
        isRegular: this.isRegular,
        subSectionId: this.subSectionId,
      });
      return data;
    },
  },
  methods: {
    GoHome,
    GoBack,
    GoTripInfo,
    GoCondition,
    GoQuestionChassis,
    GoQuestionVessel,
    GoSummary,
    GoOverview,
    Text,
    async GoToSummary() {
      const self = this as any;
      //validation
      if (!self.$refs.form.validate()) {
        return;
      }
      this.errorMsg = "";
      if (EvcrFormValidate(this.questions)) {
        self.$store.commit(StoreMutation.emUpdateQuestions);
        GoSummary(self.$router);
      } else {
        this.errorMsg = Text("ml_114752", "Please answer all the questions first.");
      }
    },

    async confirmToSubmit() {
      const self = this as any;
      if (this.hasDefects) {
        GoSummary(self.$router);
        return false;
      }
      if (!Confirm(Text("ml_114678", "Are you sure to submit the eVCR ?"))) {
        return false;
      }
      const isGood = await this.submit();
      if (isGood) {
        this.$router.push({
          name: PageName.RecordQuestionSubmitResult,
          params: { action: SubmitStatus.Success },
        });
      }
      return false;
    },
    async submit() {
      //submit request
      const self = this as any;
      this.errorMsg = "";
      let isOk = false;
      ConsoleLog("eVCR sumbit at question page");
      isOk = await self.$store.dispatch(StoreAction.emPostNewEvcr, {
        imageApiMode: ImgeApiMode.One,
        longitude: "2",
      });
      if (this.$store.state.evcrModule.errorMsg.length > 0) {
        this.errorMsg = this.$store.state.evcrModule.errorMsg;
      }
      if (isOk) {
        this.$store.commit(StoreMutation.emResetEvcr);
        RemoveStorageEvcr();
      }
      return isOk;
    },
    goPrevious() {
      if (this.isRegular) {
        const previousId = GetPreviousSubSectionId(this.questions, this.subSectionId);
        if (previousId.length > 0) {
          GoQuestion(this.$router, previousId);
        }
      } else {
        //go to last regular subsection
        const lastReguarSubSectionId = GetLastRegualrSubSectionId(this.questions);
        if (lastReguarSubSectionId.length > 0) {
          GoQuestion(this.$router, lastReguarSubSectionId);
        }
      }
    },
    async goNext() {
      const self = this as any;
      //validation
      if (!self.$refs.form.validate()) {
        return;
      }
      this.errorMsg = "";
      if (this.getData.length > 0 && !EvcrSectionValidate(this.getData[0])) {
        this.errorMsg = Text("ml_114752", "Please answer all the questions first.");
        return;
      }
      self.$store.commit(StoreMutation.emUpdateQuestions);
      if (this.isRegular) {
        const nextSubSectionId = GetNextSubSectionId(this.questions, this.subSectionId);
        if (nextSubSectionId.length > 0) {
          GoQuestion(self.$router, nextSubSectionId);
        } else {
          const questionsTmp = this.questions as IQuestionSection;
          if (questionsTmp.containAdditionalQuestion) {
            GoQuestion(self.$router, STR_ADDITION_ID);
          }
        }
      } else {
        //submit if needed
        this.errorMsg = "";
        if (EvcrFormValidate(this.questions)) {
          await this.confirmToSubmit();
        } else {
          this.errorMsg = Text("ml_114752", "Please answer all the questions first.");
        }
      }
    },
  },
  mounted() {
    ConsoleLog(["QueSection.mounted", this.isRegular]);
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  },
  beforeMount() {
    if (this.subSectionId === STR_ADDITION_ID) {
      this.isRegular = false;
    }
    const preNexStatus = SetFirtLastSubsection(
      this.questions,
      this.subSectionId,
      this.isRegular
    );
    this.notLast = preNexStatus.notLast;
    this.notFirst = preNexStatus.notFirst;
    ConsoleLog({
      "QueSection.beforeMount": preNexStatus,
      subSectionId: this.subSectionId,
      isRegular: this.isRegular,
    });
  },
});
