export function OrderedByPlantId(firstElement: any, secondElement: any) {
    return OrderedBySpecialColumn(firstElement, secondElement, 'plantId');
  }
  export function OrderedByVehicleId(firstElement: any, secondElement: any) {
    return OrderedBySpecialColumn(firstElement, secondElement, 'VehcileID');
  }
  export function OrderedBySubSectionId(firstElement: any, secondElement: any) {
    return OrderedBySpecialColumn(firstElement, secondElement, 'SectionID');
  }
    export function OrderedBySequnceId(firstElement: any, secondElement: any) {
    return OrderedBySpecialColumn(firstElement, secondElement, 'SequenceID');
  }

  export function OrderedBySpecialColumn(firstElement: any, secondElement: any, columnName: string) {
    if (
      !firstElement ||
      firstElement[columnName] === undefined ||
      !secondElement ||
      secondElement[columnName] === undefined
    ) {
      return 0;
    }
    // ignore case sensitive
    return firstElement[columnName].toString().toLowerCase() < secondElement[columnName].toString().toLowerCase()
      ? -1
      : 1;
  }