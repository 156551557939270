


























import Vue from "vue";
// in full builds helpers are exposed as Vuex.mapState
import { mapState } from "vuex";
import { AddBase64Head, GoBack, RemoveBase64Head } from "@/utility/ui-utility";
// import RecordQuestionHead from './RecordQuestionHead.vue'
// import RecordQuestionRegular from './RecordQuestionRegular.vue'
// import RecordQuestionAdditional from './RecordQuestionAdditional.vue'
import { ConsoleLog, IsNullOrEmpty } from "@/utility";
// import HomePage from "../components/HomePage.vue";

export default Vue.extend({
  components: {
    // RecordQuestionHead,
    // RecordQuestionRegular,
    // RecordQuestionAdditional,
    // HomePage,
  },
  data() {
    return {};
  },
  props: {
    recordQuestionSummaryRegularItemData: {
      type: Object,
    },
  },
  computed: {},
  methods: {
    IsNullOrEmpty,
    AddBase64Head,
    ConsoleLog,
    submitOrder() {},
  },
  mounted() {
    // ConsoleLog([
    //   "img src",
    //   AddBase64Head(this.recordQuestionSummaryRegularItemData.attachmentBase64),
    // ]);
  },
});
