export interface IMaintainNotification {
    vehicleEquipment: string;
    desc: string;
    // vehicleType: string;
    notificationId: string;
    defectDesc: string;
    date: string;
    time: string;
    status: string;
}

export class MaintainNotification implements IMaintainNotification {
    vehicleEquipment: string;
    desc: string;
    // vehicleType: string;
    notificationId: string;
    defectDesc: string;
    date: string;
    time: string;
    status: string;
    constructor(
        vehicleEquipment: string,
        desc: string,
        // vehicleType: string,
        notificationId: string,
        defectDesc: string,
        date: string,
        time: string,
        status: string,
    ) {
        this.vehicleEquipment = vehicleEquipment;
        this.desc = desc;
        // this.vehicleType = vehicleType;
        this.notificationId = notificationId;
        this.defectDesc = defectDesc;
        this.date = date;
        this.time = time;
        this.status = status;
    }
}


export interface INotificationFilter {
    tractor: string;
    thirdParty: string;
    chassis: string;
    vessel: string;
    language: string;
}

export class NotificationFilter implements INotificationFilter {
    tractor: string;
    thirdParty: string;
    chassis: string;
    vessel: string;
    language: string;

    constructor(
        tractor: string,
        thirdParty: string,
        chassis: string,
        vessel: string,
        language: string,
    ) {
        this.tractor = tractor;
        this.thirdParty = thirdParty;
        this.chassis = chassis;
        this.vessel = vessel;
        this.language = language;

    }
}
export function CreateNotification() {
    return new MaintainNotification("", "", "", "", "", "", "");
}