






























import { ImgeApiMode, PageName, SubmitStatus } from "@/models/enums";
import Vue from "vue";
import { GoBack, RemoveStorageEvcr } from "@/utility/ui-utility";
import { ConsoleLog, Text } from "@/utility";
import { StoreAction, StoreMutation } from "@/store/store-mapping";
export default Vue.extend({
  components: {
    // RecordOverviewItem,
    // HomePage,
  },
  data() {
    return {
      errorMsg: "",
    };
  },
  computed: {},
  methods: {
    Text,
    GoBack,
    async onOkay() {
      const self = this as any;
      self.errorMsg = "";
      
      //const isGood = await this.submit();
      //if (isGood) {
      //  this.$router.push({
      //    name: PageName.RecordQuestionSubmitResult,
      //    params: { action: SubmitStatus.Success },
      //  });
      //}

      this.$router.push({
        name: PageName.RecordQuestionSubmitSign,
      });
    },
    async submit() {
      //submit request
      const self = this as any;
      let isOk = false;
      ConsoleLog("eVCR sumbit at question page");
      isOk = await self.$store.dispatch(StoreAction.emPostNewEvcr, {
        imageApiMode: ImgeApiMode.One,
        longitude: "2",
      });
      if (this.$store.state.evcrModule.errorMsg.length > 0) {
        this.errorMsg = this.$store.state.evcrModule.errorMsg;
      }
      if (isOk) {
        this.$store.commit(StoreMutation.emResetEvcr);
        RemoveStorageEvcr();
      }
      return isOk;
    },
  },
});
