import config from '@/config/config.json';
import { CustomResponse } from '@/models/service';
import { ConsoleLog, Timeout } from '@/utility';
import APIService, { apiService } from './APIService';
import { GeneratePlantList } from './data-process/plant-process';
import { serviceAddress } from './service-address';
import { AsyncFetchGet } from './shared-service';

export async function GetNearByPlantList(longitude: string, latitude: string) {
    // const countryCode = await GetCountryCodeByLatLng(latitude, longitude);
    // // test when API is down
    // // if (countryCode && countryCode.isSuccess === false) {
    // //     countryCode = "us";
    // // }
    // const countryCode = "CN"
    // const url = `${config.app.api_domain}apim/lb/customers/v1/nearestcustomers?Region=EU&CountryCode=${countryCode}&MilesFlag=1&Radius=3&Latitude=${latitude}&Longitude=${longitude}`;

    // const urlPath = 'sap/equipment/v1/eVCRPlantSet?$filter=Slong eq 114.273435700 and Slat eq 34.806491900000 and Distance eq 10000&$format=json'
    // Test location
    // const url = serviceAddress.GenerateNearbyPlantsGetUrl('114.273435700', '34.806491900000');
    const url = serviceAddress.GenerateNearbyPlantsGetUrl(longitude, latitude);
    const response = await AsyncFetchGet(url);
    if (response.isSuccess === false) {
        return new CustomResponse(response, false);
    }
    // await Timeout(10000);
    const returnVal = GeneratePlantList(response);
    return new CustomResponse(returnVal);
}

export async function GetPlantList(countryCode:string) {
    // countryCode ='CN'
    const url = serviceAddress.GenerateCounryPlantsGetUrl(countryCode);
    const response = await AsyncFetchGet(url);
    if (response.isSuccess === false) {
        return new CustomResponse(response, false);
    }
    // await Timeout(10000);
    const returnVal = GeneratePlantList(response);
    return new CustomResponse(returnVal);
}