import { PageName } from '@/models/enums';
import { authService } from '@/services/AuthorService';
import { ConsoleLog, IsNullOrEmpty } from '@/utility';
import Vue from 'vue';
import VueRouter from 'vue-router';
import { routes } from './routes';
// import { authService } from '@/services/AuthService';
import config from '@/config/config.json';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  // scrollBehavior(to, from, savedPosition) {
  //   if (savedPosition) {
  //     // return savedPosition
  //   } else {
  //     if (to.hash) {
  //       return {
  //         // x, y as top-level variables define position not offset
  //         selector: to.hash,
  //         // offset has to be set as an extra object
  //         // offset: { x: 0, y: 64 }
  //         behavior: 'smooth',
  //       }
  //     }
  //   }
  // }
});

router.beforeEach((to, from, next) => {
  // ConsoleLog({ "router.beforeEach": to, "from": from, 'next': next });
  if (to.matched.some((record) => record.meta.isSecure)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    // ConsoleLog("router.beforeEach.authService.isLogin()");
    authService.isLogin().then((isLoggedIn: boolean) => {
      if (isLoggedIn) {
        if (from.name === null && to.name !== PageName.HomePage) {
          // ConsoleLog("router.beforeEach.from.name === null && to.name !== PageName.HomePage");
          next({ path: '/' })
        } else {
          next();
        }
      } else {
        // ConsoleLog({ "router.beforeEach.notLogin": to, "from": from, 'next': next });
        // next({ path: '/' })
        ConsoleLog(`not log in, redirect to` + `${config.app.fuelHome}`);
        window.open(config.app.fuelHome, "_self");
      }
    });
  } else {
    next();
  }

  // // // ConsoleLog({ "router.beforeEach.to": to, "from": from, 'next': next });
  // // if (from.name === null && to.name !== PageName.HomePage) {
  // //   // ConsoleLog({ "router.beforeEach.redirect": to, "from": from, 'next': next });
  // //   next({ path: '/' })
  // // } else {
  // //   next();
  // // }

});

export default router;
