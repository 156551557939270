// import { ServiceCallResponse } from "@/models/service";
// import { ConsoleLog, IsNullOrEmpty } from "@/utility";
import config from '@/config/config.json';
import { ServiceCallResponse } from "@/models/service";
import { ConsoleLog } from "@/utility";
import { AuthResponse } from "msal";
import { apiService } from "./APIService";
import { authService } from "./AuthorService";


export async function AsyncFetchGet(url: string) {
    const token = await authService.getAccessToken();
    ConsoleLog({ "AsyncFetchGet.token": token })
    const response = await apiService.GetRequest(url, "token");
    await UnAuthCheck(response);
    return response;
}
export async function AsyncFetchGetByToken(url: string) {
    const token = await authService.getAccessToken();
    ConsoleLog({ "AsyncFetchGetByToken.token": token })
    if (!token) {
        ConsoleLog({ "AsyncFetchGetByToken": token });
        console.log("before AsyncFetchGetByToken.authService.login()");
        authService.login();
        return new ServiceCallResponse(false, "AsyncFetchGetByToken.token doesn't exist", '', [], 401);
    } else {
        const tokenString = token.accessToken;
        const response = await apiService.GetRequestToken(url, tokenString);
        await UnAuthCheck(response);
        return response;
    }
}

export async function AsyncFetchPost(url: string, post: any) {
    const token = await authService.getAccessToken();
    // const accessToken = token?.accessToken ?? "";
    ConsoleLog({ "AsyncFetchPost.token": token })
    if (!token) {
        console.log("before AsyncFetchPost.authService.login()");
        authService.login();
        return new ServiceCallResponse(false, "AsyncFetchPost.token doesn't exist", '', [], 401);
    } else {
        const tokenString = token.accessToken;
        const response = await apiService.PostRequest(url, post, tokenString);
        await UnAuthCheck(response);
        return response;
    }

}

async function UnAuthCheck(response: any) {
    if (response.isSuccess === false && response.status === 401) {
        console.log("before UnAuthCheck.authService.login()");
        window.location.assign(config.app.noAccessUrl);
        // authService.logout();
    }
}

async function CheckToken() {
    const token = await authService.getAccessToken();
    ConsoleLog({ "CheckToken.token": token })
    if (!token) {
        ConsoleLog({ "CheckToken": token });
        console.log("before UnAuthCheck.authService.login()");
        authService.login();
        return new ServiceCallResponse(false, "AsyncFetchGetByToken.token doesn't exist", '', [], 401);
    } else {
        return token;
    }
}
