import { PicType } from "../enums";
import { Text } from "@/utility"
import { CreateSubSection, SubSection } from "./sub-section";

export interface IQuestionSection {
    title: string;
    imagePath: string;
    isSelected: boolean;
    containDefect: boolean;
    containTopDefect: boolean;
    regularQuestionCollection: SubSection[];
    containAdditionalQuestion: boolean;
    additionalQuestionContainDefect: boolean;
    additionalQuestionCollection: SubSection[];
    imageName: string;
    thumbnailName: string;
}

export class QuestionSection implements IQuestionSection {
    title: string;
    imagePath: string;
    isSelected: boolean;
    containDefect: boolean;
    containTopDefect: boolean;
    regularQuestionCollection: SubSection[];
    containAdditionalQuestion: boolean;
    additionalQuestionContainDefect: boolean;
    additionalQuestionCollection: SubSection[];
    imageName: string;
    thumbnailName: string;
    constructor(
        title: string,
        imagePath: string,
        isSelected: boolean,
        containDefect: boolean,
        containAdditionalQuestion: boolean,
        additionalQuestionContainDefect: boolean,
    ) {
        this.title = title;
        this.imagePath = imagePath;
        this.isSelected = isSelected;
        this.containDefect = containDefect;
        this.regularQuestionCollection = [];
        this.containAdditionalQuestion = containAdditionalQuestion;
        this.additionalQuestionContainDefect = additionalQuestionContainDefect;
        this.additionalQuestionCollection = [];
        this.imageName = "";
        this.thumbnailName = "";
        this.containTopDefect = false;
    }
}

export function CreateQuestionSection() {
    const section = new QuestionSection("", PicType.Tractor, false, false, false, false);
    section.regularQuestionCollection.push(CreateSubSection());
    section.additionalQuestionCollection.push(CreateSubSection());
    return section;
}

export function CreateQuestionTractor(containAdditionalQuestion: boolean = true) {
    return new QuestionSection(Text('ml_114631', "Tractor/Rigid/Truck"), PicType.Tractor, true, false, containAdditionalQuestion, false);
}

export function CreateQuestionChassis(isSelected: boolean = true, containAdditionalQuestion: boolean = true) {
    return new QuestionSection(Text('ml_114633', 'Chassis'), PicType.Chassis, isSelected, false, containAdditionalQuestion, false);
}

export function CreateQuestionVessel(isSelected: boolean = true, containAdditionalQuestion: boolean = true) {
    return new QuestionSection(Text('ml_114686', "Pressure Vessel"), PicType.Vessel, isSelected, false, containAdditionalQuestion, false);
}
export function CreateQuestionAll(isSelected: boolean = true, containAdditionalQuestion: boolean = true) {
    return new QuestionSection('All Questions', PicType.ALl, isSelected, false, containAdditionalQuestion, false);
}

export interface IQuestionFilter {
    tractor: string;
    thirdParty: boolean;
    chassis: string;
    vessel: string;
    isTractorThirdParty:string;
    tractorThirdPartyCategory:string;
    isChassisThirdParty:string;
    chassisThirdPartyCategory:string;
    isVesselThirdParty:string;
    vesselThirdPartyCategory:string;
    language: string;
    countryCode: string;
}

export class QuestionFilter implements IQuestionFilter {
    tractor: string;
    thirdParty: boolean;
    chassis: string;
    vessel: string;
    isTractorThirdParty:string;
    tractorThirdPartyCategory:string;
    isChassisThirdParty:string;
    chassisThirdPartyCategory:string;
    isVesselThirdParty:string;
    vesselThirdPartyCategory:string;
    language: string;
    countryCode: string;

    constructor(
        tractor: string,
        thirdParty: boolean,
        chassis: string,
        vessel: string,
        isTractorThirdParty:string,
        tractorThirdPartyCategory:string,
        isChassisThirdParty:string,
        chassisThirdPartyCategory:string,
        isVesselThirdParty:string,
        vesselThirdPartyCategory:string,
        language: string,
        countryCode: string,
    ) {
        this.tractor = tractor;
        this.thirdParty = thirdParty;
        this.chassis = chassis;
        this.vessel = vessel;
        this.isTractorThirdParty=isTractorThirdParty;
        this.tractorThirdPartyCategory=tractorThirdPartyCategory;
        this.isChassisThirdParty=isChassisThirdParty;
        this.chassisThirdPartyCategory=chassisThirdPartyCategory;
        this.isVesselThirdParty=isVesselThirdParty;
        this.vesselThirdPartyCategory=vesselThirdPartyCategory;
        this.language = language;
        this.countryCode = countryCode;
    }
}
