import { PageName } from '@/models/enums';
import { RouteConfig } from 'vue-router';
import HomeEntry from '../views/HomeEntry.vue';
import SearchPage from '../views/SearchPage.vue';
import TripInformation from '../views/TripInformation.vue';
import VehicleCondition from '../views/VehicleCondition.vue';
import RecordOverview from '../views/RecordOverview.vue';
// import RecordQuestion from '../views/RecordQuestion.vue';
import RecordQuestionDetail from '../views/RecordQuestionDetail.vue';
import RecordQuestionDefect from '../views/RecordQuestionDefect.vue';
import RecordQuestionGood from '../views/RecordQuestionGood.vue';
import RecordQuestionDefectSummary from '../views/RecordQuestionDefectSummary.vue';
import RecordQuestionSubmitNotice from '../views/RecordQuestionSubmitNotice.vue';
import RecordQuestionSubmitSign from '../views/RecordQuestionSubmitSign.vue';
import RecordQuestionSubmitResult from '../views/RecordQuestionSubmitResult.vue';
import TripInformationView from '../views/TripInformationView.vue';


import About from '../views/About.vue';

import QueSection from '../views/QueSection.vue';


export const routes: RouteConfig[] = [
    {
        path: '/',
        name: PageName.HomePage,
        component: HomeEntry
    },
    {
        path: '/search-trip',
        name: PageName.SearchPage,
        component: SearchPage,
        meta: {
            isSecure: true,
          }
    },
    {
        path: '/trip-information',
        name: PageName.TripInformation,
        component: TripInformation,
        meta: {
            isSecure: true,
          }
    },
    {
        path: '/vehicle-condition',
        name: PageName.VehicleCondition,
        component: VehicleCondition,
        meta: {
            isSecure: true,
          }
    },
    {
        path: '/record-overview',
        name: PageName.RecordOverview,
        component: RecordOverview,
        meta: {
            isSecure: true,
          }
    },
    // {
    //     path: '/record-question/:type',
    //     name: PageName.RecordQuestion,
    //     component: RecordQuestion,
    //     props: true,
    //     meta: {
    //         isSecure: true,
    //       }
    // },
    // {
    //     path: '/record-question/:type',
    //     name: PageName.RecordQuestionChassis,
    //     component: RecordQuestion,
    //     props: true,
    //     meta: {
    //         isSecure: true,
    //       }
    // },
    // {
    //     path: '/record-question/:type',
    //     name: PageName.RecordQuestionVessel,
    //     component: RecordQuestion,
    //     props: true,
    //     meta: {
    //         isSecure: true,
    //       }
    // },
    {
        path: '/record-question/:subSectionId',
        name: PageName.QueSection,
        component: QueSection,
        props: true,
        meta: {
            isSecure: true,
          }
    },

    {
        path: '/record-question-detail',
        name: PageName.RecordQuestionDetail,
        component: RecordQuestionDetail,
        meta: {
            isSecure: true,
          }
    },
    {
        path: '/record-question-defect',
        name: PageName.RecordQuestionDefect,
        component: RecordQuestionDefect,
        props: true,
        meta: {
            isSecure: true,
          }
    },
    {
        path: '/record-question-good',
        name: PageName.RecordQuestionGood,
        component: RecordQuestionGood,
        props: true,
        meta: {
            isSecure: true,
          }
    },
    {
        path: '/record-question-defect-summary',
        name: PageName.RecordQuestionDefectSummary,
        component: RecordQuestionDefectSummary,
        props: true,
        meta: {
            isSecure: true,
          }
    },
    {
        path: '/record-question-submit-notice',
        name: PageName.RecordQuestionSubmitNotice,
        component: RecordQuestionSubmitNotice,
        meta: {
            isSecure: true,
          }

    },
    {
        path: '/record-question-submit-sign',
        name: PageName.RecordQuestionSubmitSign,
        component: RecordQuestionSubmitSign,
        meta: {
            isSecure: true,
          }

    },
    {
        path: '/record-question-submit-result/:action',
        name: PageName.RecordQuestionSubmitResult,
        component: RecordQuestionSubmitResult,
        props: true,
        meta: {
            isSecure: true,
          }
    },
    {
        path: '/trip-information-view',
        name: PageName.TripInformationView,
        component: TripInformationView,
        props: true,
        meta: {
            isSecure: true,
          }
    },
    // {
    //     path: '/questions-view',
    //     name: PageName.QuestionsView,
    //     component: QuestionsView,
    //     props: true,
    //     meta: {
    //         isSecure: true,
    //       }
    // },
    {
        path: '/about',
        name: PageName.About,
        component: About,
    },
];

// export const GenerateTransitionName = (to: any, from: any) => {
//     let historyGo = false;
//     if (from.meta.his) {
//         historyGo = true;
//         from.meta.his = false;
//     }
//     return historyGo ? 'fold-right' : 'fold-left';
// };