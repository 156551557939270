import { Text } from "@/utility";

export interface ISelectItem {
    itemText: string;
    itemValue: string;
}

export class SelectItem implements ISelectItem {
    itemText: string;
    itemValue: string;

    constructor(itemText: string, itemValue: string) {
        this.itemText = itemText;
        this.itemValue = itemValue;
    }
}
