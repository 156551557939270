export interface IServiceCallResponse {
  isSuccess: boolean;
  error: string;
  warning: string;
  result: any[];
  status: number;
}

export class ServiceCallResponse implements IServiceCallResponse {
  isSuccess: boolean;
  error: string;
  warning: string;
  result: any[];
  status: number;

  constructor(isSuccess: boolean, error: string, warning: string, result: any[], status: number = 200) {
    this.isSuccess = isSuccess;
    this.error = error;
    this.warning = warning;
    this.result = result;
    this.status = status;
  }
}

export interface ICustomResponse {
  isSuccess: boolean;
  data: any;
}

export class CustomResponse implements ICustomResponse {
  isSuccess: boolean;
  data: any;

  constructor(data: any, isSuccess: boolean = true) {
    this.isSuccess = isSuccess;
    this.data = data;
  }
}

