import { IImagePost } from "./image-post";

export interface IQuestionPost {
    EquipmentID: string;
    VehicleId3rd:string;
    CodeGroup: string;
    Code: string;
    Charact: string;
    SectionID: string;
    SequenceID: string;
    NotificationInd: boolean;
    Answer: string;
    Comments: string;
    QuestionDesc: string;
    HardStop:string;
    Image: IImagePost | String;
}

export class QuestionPost implements IQuestionPost {
    EquipmentID: string;
    VehicleId3rd:string;
    CodeGroup: string;
    Code: string;
    Charact: string;
    SectionID: string;
    SequenceID: string;
    NotificationInd: boolean;
    Answer: string;
    Comments: string;
    QuestionDesc: string;
    HardStop:string;
    Image:  String;

    constructor(
        EquipmentID: string,
        VehicleId3rd:string,
        CodeGroup: string,
        Code: string,
        Charact: string,
        SectionID: string,
        SequenceID: string,
        NotificationInd: boolean,
        Answer: string,
        Comments: string,
        QuestionDesc: string,
        HardStop:string,
        Image:  string,
    ) {
        this.EquipmentID = EquipmentID;
        this.VehicleId3rd=VehicleId3rd;
        this.CodeGroup = CodeGroup;
        this.Code = Code;
        this.Charact = Charact;
        this.SectionID = SectionID;
        this.SequenceID = SequenceID;
        this.NotificationInd = NotificationInd;
        this.Answer = Answer;
        this.Comments = Comments;
        this.QuestionDesc = QuestionDesc;
        this.HardStop=HardStop;
        this.Image = Image
    }
}

