























import { PicType } from "@/models/enums";
import * as consts from "@/models/consts";
import { ConsoleLog, Timeout } from "@/utility";
import { ImagePath, GetQueSectionUrl, SubSectionStyle } from "@/utility/ui-utility";
import Vue from "vue";
import { mapGetters } from "vuex";
import { StoreGetter } from "@/store/store-mapping";

export default Vue.extend({
  props: {
    questions: {
      type: Object,
    },
  },
  data() {
    return {
      sectionData: [
        1,
        2,
        3,
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13,
        14,
        15,
        16,
        17,
        18,
        19,
        20,
        21,
        22,
        23,
        24,
        25,
      ],
      STR_ADDITION_ID: consts.STR_ADDITION_ID,
    };
  },
  computed: {
    // ...mapGetters({
    //   questions: StoreGetter.emQuestions,
    // }),
  },
  methods: {
    ImagePath,
    GetQueSectionUrl,
    SubSectionStyle,
  },
});
