













































import Vue from "vue";
import RecordQuestionHead from '../components/RecordQuestionHead.vue'
import RecordQuestionRegular from '../components/RecordQuestionRegular.vue'
import RecordQuestionAdditional from '../components/RecordQuestionAdditional.vue'
// import HomePage from "../components/HomePage.vue";

export default Vue.extend({
  components: {
    RecordQuestionHead,
    RecordQuestionRegular,
    RecordQuestionAdditional,
    // HomePage,
  },
  data() {
    return {
    }
  },
  computed: {

  },
  methods: {
    // next() {
    //   this.step += 1
    // },
    // previous() {
    //   this.step -= 1
    // },
    goToRecordQuestionDefectSummary() {
      this.$router.push({
        name: 'RecordQuestionDefectSummary',
        // params: { recordQuestionData: questionRegular },
      })
    },
    submitOrder() {
      this.$router.push({ name: 'thankyou' })
    },
  },
});
