





































import { ImgeApiMode, PageName, SubmitStatus } from "@/models/enums";
import { ConsoleLog, Timeout, Text } from "@/utility";
import Vue from "vue";
import { GoBack, GoHome, GoSummary, RemoveStorageEvcr } from "@/utility/ui-utility";
import { StoreAction, StoreMutation } from "@/store/store-mapping";
export default Vue.extend({
  components: {
    // RecordOverviewItem,
    // HomePage,
  },
  props: {
    action: String,
  },
  data() {
    return {
      //   submitStatus: SubmitStatus,
      SubmitStatus,
      errorMsg: "",
    };
  },
  computed: {},
  methods: {
    Text,
    GoBack,
    goPre() {
      GoSummary(this.$router);
    },
    async onOkay() {
      await Timeout(3000);
      GoHome(this.$router, false);
    },
    async submit() {
      //submit request
      this.errorMsg = "";
      const self = this as any;
      let isOk = false;
      ConsoleLog("eVCR sumbit at question page");
      isOk = await self.$store.dispatch(StoreAction.emPostNewEvcr, {
        imageApiMode: ImgeApiMode.One,
        longitude: "2",
      });
      if (this.$store.state.evcrModule.errorMsg.length > 0) {
        this.errorMsg = this.$store.state.evcrModule.errorMsg;
      }
      if (isOk) {
        self.$store.commit(StoreMutation.emResetEvcr);
        RemoveStorageEvcr();
        await this.onOkay();
      }
      return isOk;
    },
  },
  watch: {
    action: {
      immediate: true,
      handler: async function (newValue: string) {
        ConsoleLog({ "watch.submitStatus": "Current vaules:" + newValue });
        switch (newValue) {
          case SubmitStatus.Processing.toString():
            // await this.submit();
            break;
          case SubmitStatus.Success.toString():
            await this.onOkay();
            break;
        }
      },
      deep: false,
    },
  },
});
