
export enum Env {
    tch = "tch",
    Dev = "dev",
    integ = "integ",
    Prod = "prod"
}

export enum PageName {
    HomePage = "HomePage",
    SearchPage = "SearchPage",
    TripInformation = "TripInformation",
    VehicleCondition = "VehicleCondition",
    RecordOverview = "RecordOverview",
    RecordQuestionTractor = "RecordQuestionTractor",
    RecordQuestionChassis = "RecordQuestionChassis",
    RecordQuestionVessel = "RecordQuestionVessel",
    QueSection = "QueSection",
    RecordSummary = "RecordSummary",
    RecordQuestionDetail = "RecordQuestionDetail",
    RecordQuestionDefect = "RecordQuestionDefect",
    RecordQuestionGood = "RecordQuestionGood",
    RecordQuestionDefectSummary = "RecordQuestionDefectSummary",
    RecordQuestionSubmitNotice = "RecordQuestionSubmitNotice",
    RecordQuestionSubmitSign = "RecordQuestionSubmitSign",
    RecordQuestionSubmitResult = "RecordQuestionSubmitResult",
    TripInformationView = "TripInformationView",
    QuestionsView = "QuestionsView",
    About = "About",
}

export enum TractorThirdPartyCategory {
    TRACTOR = "TRACTOR",
    RIGID_PGG_TRUCK = "RIGID PGG TRUCK",
    RIGID_PGG_KNUCKLE_CRANE_TRUCK = "RIGID PGG KNUCKLE CRANE TRUCK",
    RIGID_TANKER = "RIGID TANKER",
}

export enum ChassisThirdPartyCategory {
    CHASSIS_TRAILER = "CHASSIS TRAILER",
    PGG_CHASSIS = "PGG CHASSIS",

}

export enum VesselThirdPartyCategory {
    LB_MB_ISO_TANKER = "LB&MB&ISO TANKER",
    TUBE_SKID = "TUBE SKID",

}

export enum QuestionSectionType {
    Tractor = "tractor",
    Chassis = "chassis",
    // Tanker = "Tanker",
    Vessel = "vessel",
}

export enum QuestionType {
    Regular = "Regular",
    Char = "Char",
    Number = "Number",
    Boolean = "Boolean",
}
export enum PicType {
    Tractor = "PictureTractor",
    Chassis = "PictureChassis",
    Vessel = "PictureVessel",
    ALl = "All",
}
//     answer: string; // Good,Defective and N/A
export enum RegularAnswer {
    Good = "Good",
    Defective = "Defective",
    NA = "N/A",
}

export enum SubmitStatus {
    Processing = "processing",
    Success = "success",
    Failure = "failure"
}


export enum SapVehcileType {
    Tractor = "TRACTOR",
    Chassis = "CHASSIS",
    Vessel = "VESSEL",
}
export enum SapVehcileCategory {
    Tractor = "Tractor",
    Chassis = "Chassis",
    Vessel = "Vessel",
}
export enum SapQuestionType {
    Regular = "R",
    Char = "CHAR",
    Number = "NUM",
    Boolean = "BOOL",
}
export enum LangCode {
    zh_CN = "zh-CN",
    en_GB = "en-GB",
    en_US = "en-US",
    zh_TW = "zh-TW",
    id_ID = "id-ID", //Indonesian
    id = "id", //Indonesian
    ms = "ms", //Malay
    ms_MY = "ms-MY", //Malay
}

export enum SapLanguage {
    Chinese = "ZH",
    English = "EN",
    Malay = "MS",
    Indonesian = "ID",
    ChineseZF = "ZF",
}

export enum SapReturnCode {
    ExistingEvcr_961 = 'ZERP3/961',
    TractorNotExisting_962 = 'ZERP3/962',
    ChassisNotExisting_963 = 'ZERP3/963',
    VesselNotExisting_964 = 'ZERP3/964',
    ApexIDNotExisting_965 = 'ZERP3/965',
    NonAccessToQueTemplate_966 = 'ZERP3/966',
    NonAccessToEvcRecord_967 = 'ZERP3/967',
    NonAccessToCreatEvc_968 = 'ZERP3/968',
    ErrorSaveEvc_969 = 'ZERP3/969',
    // Exist_961 = 'ZERP3/961',
    // Exist_961 = 'ZERP3/961',
    // Exist_961 = 'ZERP3/961',
    // Exist_961 = 'ZERP3/961',
    // Exist_961 = 'ZERP3/961',
    // Exist_961 = 'ZERP3/961',
}

export enum ImgeApiMode {
    AllNotWait = "all",
    AllWait = "allWait",
    One = "one",
    Parallel = "parallel",
    // None = "none",
}

export enum TripType {
    LB = "LB",
    PG = "PG",
    Other = "OT"
}

