
import Vue from 'vue'
import Vuex, { Commit, Dispatch } from 'vuex'
import * as getters from './getters';
import * as actions from './actions';
import * as mutations from './mutations';
import { state } from './state';
import { saveStatePlugin } from '../utility/saveStatePlugin'
import { lastEvcrModule } from './modules/last-evcr-module';
import { StoreMutation, StoreAction } from './store-mapping';
import { evcrModule } from './modules/evcr-module';
import { tmpEvcrModule } from './modules/tmp-evcr-module'
import { userModule } from './modules/user-module';

Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [saveStatePlugin],
  state,
  getters,
  actions,
  mutations,
  modules: {
    evcrModule,
    lastEvcrModule,
    tmpEvcrModule,
    userModule,
  },
});

export async function BaseDataLoadingAction(commit: Commit, action: () => any) {
  commit(StoreMutation.StartLoading, null, { root: true });
  await action();
  commit(StoreMutation.EndLoading, null, { root: true });
}