import { IQuestionPost, QuestionPost } from "./question-post";

export interface IEvcrPost {
    TripType: string;
    LBShellTrip: string;
    PrePost: string;
    Plant: string;
    Driver1: string;
    // Driver1Name: string;
    Driver2: string;
    TractorLicense: string;
    ThirdPartyInd: string;
    ChassisLicense: string;
    LBShellID: string;
    OdoMeter: string;
    // TimeStamp: string;
    Date: string;
    Time: string;
    Tractor3rdParty:string;
    Chassis3rdParty:string;
    Vessel3rdParty:string;
    Country:string;
    eVCRRecordItemSet: IQuestionPost[];
}

export class EvcrPost implements IEvcrPost {
    TripType: string;
    LBShellTrip: string;
    PrePost: string;
    Plant: string;
    Driver1: string;
    Driver1Name: string;
    Driver2: string;
    TractorLicense: string;
    ThirdPartyInd: string;
    ChassisLicense: string;
    LBShellID: string;
    OdoMeter: string;
    // TimeStamp: string;
    Date: string;
    Time: string;
    Tractor3rdParty:string;
    Chassis3rdParty:string;
    Vessel3rdParty:string;
    Country:string;
    eVCRRecordItemSet: IQuestionPost[];
    constructor(
        TripType: string,
        LBShellTrip: string,
        PrePost: string,
        Plant: string,
        Driver1: string,
        Driver1Name: string,
        Driver2: string,
        TractorLicense: string,
        ThirdPartyInd: string,
        ChassisLicense: string,
        LBShellID: string,
        OdoMeter: string,
        // TimeStamp: string
        Date: string,
        Time: string,
        Tractor3rdParty:string,
        Chassis3rdParty:string,
        Vessel3rdParty:string,
        Country:string,
    ) {
        this.TripType = TripType;
        this.LBShellTrip = LBShellTrip;
        this.PrePost = PrePost;
        this.Plant = Plant;
        this.Driver1 = Driver1;
        this.Driver1Name = Driver1Name;
        this.Driver2 = Driver2;
        this.TractorLicense = TractorLicense;
        this.ThirdPartyInd = ThirdPartyInd;
        this.ChassisLicense = ChassisLicense;
        this.LBShellID = LBShellID;
        this.OdoMeter = OdoMeter;
        // this.TimeStamp = TimeStamp;
        this.Date = Date;
        this.Time = Time;
        this.Tractor3rdParty=Tractor3rdParty;
        this.Chassis3rdParty=Chassis3rdParty;
        this.Vessel3rdParty=Vessel3rdParty;
        // this.eVCRRecordItemSet = eVCRRecordItemSet;
        this.Country=Country;
        this.eVCRRecordItemSet = [];
    }
}

