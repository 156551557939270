export interface IVehicle {
    vehicleId: string;
    vesselId: string;
    category: string;
    category2: string;
    country: string;
    plant: string;
    name1: string;
}

export class Vehicle implements IVehicle {
    vehicleId: string;
    vesselId: string;
    category: string;
    category2: string;
    country: string;
    plant: string;
    name1: string;    

    constructor(
        vehicleId: string,
        vesselId: string,
        category: string,
        category2: string,
        country: string,
        plant: string,
        name1: string,

    ) {
        this.vehicleId = vehicleId;
        this.vesselId = vesselId;
        this.category = category;
        this.category2 = category2;
        this.country = country;
        this.plant = plant;
        this.name1 = name1;
    }
}
export function CreateVehicle() {
    return new Vehicle("", "","", "", "", "", "");
}