
















































import Vue from "vue";
import { StoreAction, StoreGetter, StoreMutation } from "@/store/store-mapping";
import {
  GoTripInfo,
  GoSearch,
  Confirm,
  RemoveStorageEvcr,
  RegularItems,
  VehicleThumbnail,
  GetPicSize,
} from "@/utility/ui-utility";
import { mapGetters } from "vuex";
import { ConsoleLog, DeepClone, Text } from "@/utility";
import { VehiclePath, FuelHome } from "@/utility/ui-utility";
import FormSelect from "@/components/FormSelect.vue";
import SectionList from "@/components/SectionList.vue";
import FormRegular from "@/components/FormRegular.vue";
import { mdiHome, mdiHomeCircle } from "@mdi/js";
export default Vue.extend({
  name: "HomePage",
  components: { FormSelect, SectionList, FormRegular },
  data: () => ({
    isLoggedIn: false,
    loginError: "",
    question: "",
    icons: {
      mdiHome,
      mdiHomeCircle,
    },
    newDisable: false,
    editDisable: false,
  }),
  async created() {
    await this.initialize();
    // if (IsUserLogin()) {
    //   this.isLoggedIn = true;
    // }
    ConsoleLog({ "HomePage.beforeMount.userProfile": DeepClone(this.userProfile) });
    if (this.userProfile) {
      this.isLoggedIn = true;
    }
  },
  computed: {
    ...mapGetters({
      editable: StoreGetter.temEditable,
      userProfile: StoreGetter.umUserProfile,
    }),
  },
  methods: {
    RegularItems,
    VehiclePath,
    FuelHome,
    editClick(e: any) {
      ConsoleLog({ HomeEditCick: e });
    },
    questionClick(e: any) {
      ConsoleLog({ HomeQuestionCick: e });
    },
    GoTripInfo,
    GoSearch,
    Text,
    async initialize() {
      const self = this as any;
      await self.$store.dispatch(StoreAction.temInitializeEvcrData, {
        longitude: "test",
      });
    },
    async getInitialize() {
      const self = this as any;
      await self.$store.dispatch(StoreAction.emGetFive, {
        longitude: "",
        latitude: "",
      });
    },
    goEdit() {
      console.log("Start of goEdit");
      const self = this as any;
      this.editDisable = true;
      this.$store.commit(StoreMutation.StartLoading, null, { root: true });
      console.log("before navigating to TripInofo, goEdit");
      GoTripInfo(self.$router);
      return false;
    },
    newEvcr() {
      console.log("Start of newEVCR");
      try {
        this.newDisable = true;
        console.log("newDisable", this.newDisable, "editable:", this.editable);
        if (this.editable) {
          const confirmMsg = Text(
            "ml_114755",
            "There is a draft eVCR, would you like to remove it and make a new eVCR?"
          );
          console.log("confirmMsg:", confirmMsg);
          if (window.confirm(confirmMsg)) {
            console.log("after Confirm");
            this.$store.commit(StoreMutation.StartLoading, null, { root: true });
            console.log(
              "this.$store.commit(StoreMutation.StartLoading, null, { root: true });"
            );
            this.$store.commit(StoreMutation.emResetEvcr);
            console.log("    this.$store.commit(StoreMutation.emResetEvcr);");
            RemoveStorageEvcr();
            console.log("RemoveStorageEvcr();");
            GoTripInfo(this.$router);
            console.log("GoTripInfo(this.$router);");
          } else {
            console.log("this.newDisable = false;");
            this.newDisable = false;
          }
        } else {
          console.log("before navigating to TripInofo, newEVCR");
          this.$store.commit(StoreMutation.StartLoading, null, { root: true });
          console.log(
            " this.$store.commit(StoreMutation.StartLoading, null, { root: true });"
          );
          this.$store.commit(StoreMutation.emResetEvcr);
          console.log("this.$store.commit(StoreMutation.emResetEvcr);");
          RemoveStorageEvcr();
          console.log("RemoveStorageEvcr()");
          GoTripInfo(this.$router);
          console.log("after navigating to TripInofo, newEVCR");
        }
      } catch (error: any) {
        this.$store.commit(StoreMutation.EndLoading, null, { root: true });
        console.log("newEVCR.error", error);
      }
      return false;
    },
    async logIn() {
      this.loginError = "";
      const auth = Vue.$authService as any;
      ConsoleLog({ "login.auth ": auth });
      try {
        const loginResponse = await auth.login();
        // following code is available only  when using loginPopup to log.
        // ConsoleLog({ "App.login ": loginResponse });
        // if (IsUserLogin()) {
        //   this.isLoggedIn = true;
        //   this.loginError = "";
        // } else {
        //   this.loginError = "Loggin failure";
        // }
      } catch (error) {
        ConsoleLog({ "login.error ": error });
        this.loginError = "Loggin failure, please try again";
      }
    },
  },

  async beforeMount() {
    // VehiclePath("Tractor_Chassis Trailer_Tube Skid_RHD.jpg");
    // VehicleThumbnail("Rigid Tanker_RHD_THUMB.jpg");
    // VehiclePath("TRACTOR_LHD.jpg");
    // GetPicSize("TRACTOR_LHD.jpg");
    this.editDisable = false;
    this.newDisable = false;
    await this.getInitialize();
  },
});
