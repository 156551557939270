




































import { ISelectItem } from "@/models/select-item";
// import {questionIcon} from "@/assets/image/question-40.png"
import { ConsoleLog, Timeout } from "@/utility";
import Vue from "vue";
import { mdiPencil, mdiMapMarkerQuestion } from "@mdi/js";

export default Vue.extend({
  props: {
    value: String || Number,
    label: String,
    items: [],
    rules: [],
    required: Boolean,
    readonly: { type: Boolean, default: false },
    showAsLabel: { type: Boolean, default: false },
    showQuestionIcon: { type: Boolean, default: false },
    showEditIcon: { type: Boolean, default: false },
  },
  data: () => ({
    icons: {
      mdiPencil,
      mdiMapMarkerQuestion,
    },
  }),

  computed: {},
  methods: {
    ConsoleLog,
    onChanging(value: string) {
      this.$emit("input", value);
    },
    onUserChanging(value: any) {
      ConsoleLog({ onUserChanging: { value: this.items } });
      this.$emit("userChange", value);
    },
    editCick(e: any) {
      // ConsoleLog({ editCick: e });
      this.$emit("userEditClick", e);
    },
    questionCick(e: any) {
      // ConsoleLog({ questionCick: e });
      this.$emit("userQuestionClick", e);
    },
  },
  mounted(): void {
    const self = this as any;
    if (self.items && self.items.length > 0) {
      self.items.forEach((item) => {
        // ConsoleLog(item);
      });
    } else {
      // ConsoleLog(["self.items", self.items]);
    }
  },
});
