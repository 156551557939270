
import { ConsoleLog, DeepClone, } from '@/utility';
import config from '@/config/config.json';
import { Commit } from 'vuex';
import { StoreMutation } from '../store-mapping';
import { HasAccess } from '@/utility';
import { authService } from '@/services/AuthorService';
interface IUserState {
    user: any | null;
    errorMsg: string;
}

export const userModule = {
    namespaced: true,
    state: () => ({
        user: null,
        errorMsg: "",
    }),
    getters: {
        userProfile: (state: IUserState) => {
            if (state.user) {
                return state.user;
            }
            return undefined;
        },
        userId: (state: IUserState) => {
            ConsoleLog({ "state.user": DeepClone(state.user) })
            if (state.user && state.user.userName) {
                const userName = state.user.userName.toString();
                ConsoleLog({ "userId": userName })
                return userName.substring(0, userName.indexOf('@'));
            }
            return '';
        },
        userName: (state: IUserState) => {
            ConsoleLog({ "state.user": DeepClone(state.user) })
            if (state.user && state.user.name) {
                ConsoleLog({ "userName": state.user.name })
                return state.user.name;
            }
            return '';
        },
        userCountryCode: (state: IUserState) => {
            if (state.user && state.user.idToken && state.user.idToken.ctry) {
                ConsoleLog({ "userCountryCode": state.user.idToken.ctry.toString() })
                return state.user.idToken.ctry.toString();
            }
            return '';
        },
    },
    mutations: {
        resetUser: (state: IUserState) => {
            state.user = null;
            state.errorMsg = "";
        },
    },
    actions: {
        login: async ({ commit, state }: { commit: Commit, state: IUserState }) => {
            console.log("Start of actions.login");
            commit(StoreMutation.StartLoading, null, { root: true });
            let user = await authService.getUser();
            if (user === null) {
                console.log("before actions.login.authService.login()");
                await authService.login();
                user = await authService.getUser();
            }
            ConsoleLog({ "login.user": DeepClone(user) });
            // // special logic to change country for test purpose
            // if (user !== null) {
            //     if(user.userName === 'ZHANGPY@airproducts.com' || user.userName === 'ZHANGW13@airproducts.com'){
            //         user.idToken.ctry = 'ID';
            //     }
            // }
            ConsoleLog({ "login.user": DeepClone(user) });
            state.user = user;
            if (!user) {
                // commit(StoreMutation.EndLoading, null, { root: true });
                return;
            }
            // // check the authorization
            // const hasAccess = HasAccess(user.idToken.roles);
            // if (hasAccess) {
            //     state.user = user;
            //     // commit(StoreMutation.endLoading, null, { root: true });
            // } else {
            //     ConsoleLog("user doesn't have access");
            //     // await authService.logout();
            //     window.location.assign(config.app.noAccessUrl);
            // }
            //This is no needed as we need getFile API
            // commit(StoreMutation.EndLoading, null, { root: true });
        }
        ,
        logout: async ({ state }: { state: IUserState }) => {
            state.user = null;
            await authService.logout();
        }
    }
};
