import { GetDatePart, GetTimePart } from "@/utility";
import { IsLocalMode, PrePostItems, TripTypetems } from "@/utility/ui-utility";
import { TripType } from "../enums";
import { ISelectItem } from "../select-item";

export interface ITripInfo {
    tripType: string,
    tripTypeItems: ISelectItem[];
    lbsTripId: string;
    otherTripId: string;
    prePost: string;
    prePostItems: ISelectItem[];
    plantId: string;
    customPlantId: string;
    driver1: string;
    driver1Name: string;
    driver2: string;
    tractor: string;
    isTractorThirdParty:boolean;
    tractorThirdPartyCategory:string;
    thirdParty: boolean;
    chassis: string;
    isChassisThirdParty:boolean;
    chassisThirdPartyCategory:string;
    vessel: string;
    isVesselThirdParty:boolean;
    vesselThirdPartyCategory:string;
    odometer: string;
    date: string;
    time: string;
    pgTripId: string;
    signImage:string;
    country:string;
    language:string;
    plantName:string;
    overviewImage:string;
}

export class TripInfo implements ITripInfo {
    tripType: string;
    tripTypeItems: ISelectItem[];
    lbsTripId: string;
    otherTripId: string;
    prePost: string;
    prePostItems: ISelectItem[];
    plantId: string;
    customPlantId:string;
    driver1: string;
    driver1Name: string;
    driver2: string;
    tractor: string;
    isTractorThirdParty:boolean;
    tractorThirdPartyCategory:string;
    thirdParty: boolean;
    chassis: string;
    isChassisThirdParty:boolean;
    chassisThirdPartyCategory:string;
    vessel: string;
    isVesselThirdParty:boolean;
    vesselThirdPartyCategory:string;
    odometer: string;
    date: string;
    time: string;
    pgTripId: string;
    signImage:string;
    country:string;
    language:string;
    plantName:string;
    overviewImage:string;
    constructor(
        tripType: string,
        tripTypeItems: ISelectItem[],
        lbsTripId: string,
        prePost: string,
        prePostItems: ISelectItem[],
        plantId: string,
        customPlantId:string,
        driver1: string,
        driver1Name: string,
        driver2: string,
        tractor: string,
        isTractorThirdParty:boolean,
        tractorThirdPartyCategory:string,
        thirdParty: boolean,
        chassis: string,
        isChassisThirdParty:boolean,
        chassisThirdPartyCategory:string,
        vessel: string,
        isVesselThirdParty:boolean,
        vesselThirdPartyCategory:string,
        odometer: string,
        date: string,
        time: string,
        signImage:string,
        country:string,
        language:string,
        plantName:string,
        overviewImage:string,
        ) {
        this.tripType = tripType;
        this.tripTypeItems = tripTypeItems;
        this.lbsTripId = lbsTripId;
        this.pgTripId = lbsTripId;
        this.otherTripId=lbsTripId;
        this.prePost = prePost;
        this.prePostItems = prePostItems;
        this.plantId = plantId;
        this.customPlantId=customPlantId;
        this.driver1 = driver1;
        this.driver1Name = driver1Name;
        this.driver2 = driver2;
        this.tractor = tractor;
        this.isTractorThirdParty = isTractorThirdParty;
        this.tractorThirdPartyCategory=tractorThirdPartyCategory;
        this.thirdParty = thirdParty;
        this.chassis = chassis;
        this.isChassisThirdParty=isChassisThirdParty;
        this.chassisThirdPartyCategory=chassisThirdPartyCategory;
        this.vessel = vessel;
        this.isVesselThirdParty=isVesselThirdParty;
        this.vesselThirdPartyCategory=vesselThirdPartyCategory;
        this.odometer = odometer;
        this.date = date;
        this.time = time;
        this.signImage=signImage;
        this.country=country;
        this.language=language;
        this.plantName=plantName;
        this.overviewImage=overviewImage;
    }
}
export function CreateTripInfo() {
    const date = new Date();
    if (IsLocalMode()) {
        return CreateTripInfoTest();
    } else {
        return new TripInfo("", TripTypetems(), "", "", PrePostItems(), "", "", "", "", '', "", false, "", false, "", false, "", "", false, "", "", GetDatePart(date), GetTimePart(date), "", "", "", "", "");
    }

}
export function CreateTripInfoTest() {
    const date = new Date();
    //HU A-T3109 沪AT3109 45Q001
    // return new TripInfo("trip07", "PO", PrePostItems(), "7153", "ZHANGW13", "TANGE", "沪AT3109", false, "陕EU002挂", "33Q003", "1000", GetDatePart(date), GetTimePart(date));
    return new TripInfo(TripType.PG, TripTypetems(), "will-202235", "PO", PrePostItems(), "7153", "7153", "ZHANGW13", "Zhang Weida", "", "沪AT3109", false, "", false, "", false, "", "", false, "", "1000", GetDatePart(date), GetTimePart(date), "", "", "", "", "");
}

