export interface IPlant {
    plantId: string;
    name1: string;
    name2: string;
    country: string;
    city: string;
    encity: string;
    enname: string;
    enname2: string;
    plantIdName: string;
    //Quick search based on Plant Name and City fields.
}

export class Plant implements IPlant {
    plantId: string;
    name1: string;
    name2: string;
    country: string;
    city: string;
    encity: string;
    enname: string;
    enname2: string;
    plantIdName: string;

    constructor(
        plantId: string,
        name1: string,
        name2: string,
        country: string,
        city: string,
        encity: string,
        enname: string,
        enname2: string,

    ) {
        this.plantId = plantId;
        this.name1 = name1;
        this.name2 = name2;
        this.country = country;
        this.city = city;
        this.encity = encity;
        this.enname = enname;
        this.enname2 = enname2;
        this.plantIdName = `${plantId}-${name1}`;
    }
}
export function CreatePlant() {
    return new Plant("", "", "",'','','','','');
}