import { Commit, Dispatch } from 'vuex'
import { ClearError, CloneObj, ConsoleLog, Timeout } from '@/utility'
import * as consts from "@/models/consts"
import { StoreMutation } from '../store-mapping'
import { RemoveStorageEvcr, SetIsEditEvcr } from '@/utility/ui-utility'

interface IState {
    eVcrData: any;
    errorMsg: string
    editable: boolean;
}

export const tmpEvcrModule = {
    namespaced: true,
    state: () => ({
        errorMsg: "",
        eVcrData: {},
        editable: false,
    }),
    getters: {
        editable: (state: IState) => {
            return state.editable;
        },
    },
    mutations: {
        resetTmpEvcr: (state: IState) => {
            state.errorMsg = "";
            state.eVcrData = {};
            state.editable = false;
        },
        setEditable: (state: IState, editable: boolean) => {
            state.editable = editable;
        },
    },
    actions: {
        initializeEvcData: async (
            {
                state,
                commit,
                dispatch,
            }: { state: IState; commit: Commit; dispatch: Dispatch },
            {
                longitude,
            }: { longitude: string; appName: string }
        ) => {
            ClearError(state);
            const tmpEvcrData =
                localStorage.getItem(consts.EVCR_DATA_KEY) &&
                    localStorage.getItem(consts.EVCR_DATA_KEY) !== "undefined"
                    ? JSON.parse(localStorage.getItem(consts.EVCR_DATA_KEY) as string)
                    : undefined;
            try {
                if (tmpEvcrData) {
                    ConsoleLog(["initializeEvcData.tmpEvcrData", CloneObj(tmpEvcrData)]);
                    state.errorMsg = "";
                    commit(StoreMutation.emInitializeState, { tmpEvcState: tmpEvcrData }, { root: true });
                    state.editable = true;
                    SetIsEditEvcr(true);
                } else {
                    ConsoleLog("initializeEvcData.tmpEvcrData doesn't exist.");
                    state.editable = false
                    RemoveStorageEvcr();
                }
            } catch (error) {
                ConsoleLog({ 'initializeEvcData failed': tmpEvcrData, 'error': error })
                state.editable = false
                RemoveStorageEvcr();
            }
        },
    },
}
