






































import Vue from "vue";
import { PropValidator } from "vue/types/options";
export default Vue.extend({
  props: {
    value: String || Number,
    label: String,
    items: [],
    rules: [],
    itemText: { type: String, default: "itemText" },
    itemValue: { type: String, default: "itemValue" },
    required: Boolean,
    readonly: { type: Boolean, default: false },
    showAsLabel: { type: Boolean, default: false },
    userFilter: {
      type: Function,
      default: (item: any, queryText: string, itemText: string) => {
        return itemText.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1;
      },
    } as PropValidator<(item: any, queryText: string, itemText: string) => boolean>,
  },
  computed: {},
  methods: {
    customFilter(item: any, queryText: string, itemText: string) {
      return this.userFilter(item, queryText, itemText);
    },
    onChanging(value: string) {
      this.$emit("input", value);
    },
    onUserChanging(value: any) {
      this.$emit("userChange", value);
    },
  },
});
