





























import { ConsoleLog } from "@/utility";
import Vue from "vue";

export default Vue.extend({
  props: {
    value: String || Number,
    label: String,
    counter: Number,
    rules: [],
    required: Boolean,
    readonly: { type: Boolean, default: false },
    showAsLabel: { type: Boolean, default: false },
    mask: { type: String, default: "" },
    placeholder: { type: String, default: "" },
    type: { type: String, default: "text" },
  },
  computed: {},
  methods: {
    onChanging(value: string) {
      ConsoleLog({ "FormInput.onChanging": value });
      this.$emit("input", value);
      this.$emit("userChange", value);
    },
    onUserChanging(value: any) {
      ConsoleLog({ "FormInput.onUserChanging": value });
      this.$emit("userChange", value);
    },
  },
});
