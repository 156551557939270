import config from '@/config/config.json';
import { INotificationFilter } from '@/models/maitain-notification';
import { IQuestionFilter } from '@/models/question-section';
import { ITripInfo, TripInfo } from '@/models/trip-info';
import { GetSapLanguage, IsNullOrEmpty } from '@/utility';
import * as consts from "@/models/consts";
import Vue from 'vue'
import { TripType } from '@/models/enums';

export class ServiceAddress {
    private appConfig: any;
    constructor(globalConfig: any) {
        this.appConfig = globalConfig.app;
    }
    private get apiDomain() {
        return this.appConfig.useLamda ? this.appConfig.api_domain_lamda : this.appConfig.api_domain_local;
    }
    private get sapLangCode() {
        return GetSapLanguage(Vue.prototype.$lanCode);
    }
    //const urlPath = 'sap/equipment/v1/eVCRPlantSet?$filter=Slong eq 114.273435700 and Slat eq 34.806491900000 and Distance eq 10000&$format=json'
    public GenerateNearbyPlantsGetUrl = (longitude: string, latitude: string) =>
        `${this.apiDomain}eVCRPlantSet?$filter=Slong eq ${longitude} and Slat eq ${latitude}&$format=json`
    // https://dev-api.airproducts.com/sap/equipment/v1/eVCRPlantSet?$filter=Country eq 'CN'&$format=json
    public GenerateCounryPlantsGetUrl = (countryCode: string) =>
        `${this.apiDomain}eVCRPlantSet?$filter=Country eq '${countryCode}'&$format=json&sap-language=${this.sapLangCode}`

    public GenerateEquipmentNotificationGetUrl = (filter: INotificationFilter) => {
        // let url = `${this.apiDomain}eVCRNotificationSet?$filter=TractorLicense eq '${filter.tractor}'`
        // if (!IsNullOrEmpty(filter.chassis)) {
        //     url += ` and ChassisLicense eq '${filter.chassis}'`;
        // }
        // if (!IsNullOrEmpty(filter.vessel)) {
        //     url += ` and LBShellID eq '${filter.vessel}'`;
        // }
        let url = `${this.apiDomain}eVCRNotificationSet?$filter=`
        let query = ``;
        let resultUqery = ``;
        if (!IsNullOrEmpty(filter.tractor)) {
            query += ` and TractorLicense eq '${filter.tractor}'`;
        }
        if (!IsNullOrEmpty(filter.chassis)) {
            query += ` and ChassisLicense eq '${filter.chassis}'`;
        }
        if (!IsNullOrEmpty(filter.vessel)) {
            query += ` and LBShellID eq '${filter.vessel}'`;
        }
        query += `&sap-language=${this.sapLangCode}`;
        query += '&$format=json';
        resultUqery = query.substring(5)
        url += resultUqery;
        return url;
    }
    //https://dev-api.airproducts.com/sap/equipment/v1/eVCRQuestionSet?$filter=TractorLicense eq 'HU A-T3109' and ThirdPartyInd eq '' and ChassisLicense eq '' and LBShellID eq ''&$format=json
    // 20230102
    // https://dev-api.airproducts.com/sap/equipment/v1/eVCRQuestionSet?$filter=TractorLicense eq '' and ChassisLicense eq '' and LBShellID eq '' 
    // and Tractor3rdParty eq 'X' and Tractor3rdPartyVehicleType eq 'TRACTOR' 
    // and Chassis3rdParty eq 'X' and Chassis3rdPartyVehicleType eq 'CHASSIS TRAILER' 
    // and Vessel3rdParty eq '' and Vessel3rdPartyVehicleType eq '' 
    // and Country eq 'CN'&sap-language=ZH&$format=json
    public GenerateQuestionGetUrl = (filter: IQuestionFilter) => {
        let url = `${this.apiDomain}eVCRQuestionSet?$filter=TractorLicense eq '${filter.tractor}'`
        let emptyString = '';
        if (filter.countryCode) {
            url += ` and Country eq '${filter.countryCode}'`;
        }
        if (filter.thirdParty) {
            url += ` and ThirdPartyInd eq '${consts.STR_THIRD_PARTY_TRUE}'`;
        }
        if (!IsNullOrEmpty(filter.chassis)) {
            url += ` and ChassisLicense eq '${filter.chassis}'`;
        }
        if (!IsNullOrEmpty(filter.vessel)) {
            url += ` and LBShellID eq '${filter.vessel}'`;
        }
        
        url += ` and Tractor3rdParty eq '${filter.isTractorThirdParty}'`;
        url += ` and Tractor3rdPartyVehicleType eq '${filter.isTractorThirdParty !== emptyString ? filter.tractorThirdPartyCategory : emptyString}'`;

        url += ` and Chassis3rdParty eq '${filter.isChassisThirdParty}'`;
        url += ` and Chassis3rdPartyVehicleType eq '${filter.isChassisThirdParty !== emptyString ? filter.chassisThirdPartyCategory : emptyString}'`;

        url += ` and Vessel3rdParty eq '${filter.isVesselThirdParty}'`;
        url += ` and Vessel3rdPartyVehicleType eq '${filter.isVesselThirdParty !== emptyString ? filter.vesselThirdPartyCategory : emptyString}'`;

        url += `&sap-language=${this.sapLangCode}`;
        url += '&$format=json';
        return url;
    }
    public GeneratePostNewEvcrUrl = () => {
        // https://dev-api.airproducts.com/sap/equipment/v1/eVCRRecordHeaderSet
        let url = `${this.apiDomain}eVCRRecordHeaderSet`
        url += '?$format=json';
        url += `&sap-language=${this.sapLangCode}`;
        return url;
    }

    public GeneratePostNewEvcrSignUrl = () => {
        // https://dev-api.airproducts.com/sap/equipment/v1/eVCRSignedTripRpt
        let url = `${this.apiDomain}eVCRSignedTripRpt`
        url += '?$format=json';
        url += `&sap-language=${this.sapLangCode}`;
        return url;
    }

    public GeneratePostEvcrImagesUrl = () => {
        // https://evcr.api.tch.fuelmgmt.airproducts.com/api/values/eVCRNotifAttachSet
        let url = `${this.apiDomain}eVCRNotifAttachSet`
        return url;
    }

    // https://evcr.api.tch.fuelmgmt.airproducts.com/api/values/eVCRRecordHeaderSet?$filter=LBShellTrip eq 'trip07' and PrePost eq 'PR' and Driver1 eq 'TANGE' and Driver2 eq 'TANGE' and TractorLicense eq '沪AT3109' and ChassisLicense eq '1234' and LBShellID eq '12345'&$format=json
    public GenerateValidateEvcrGetUrl = (filter: ITripInfo) => {
        let url = `${this.apiDomain}eVCRRecordHeaderSet?$filter=LBShellTrip eq '${filter.tripType === TripType.LB ? filter.lbsTripId : filter.pgTripId}'  and PrePost eq '${filter.prePost}' and TractorLicense eq '${filter.tractor}'`
        if (filter.driver2) {
            url += ` and Driver2 eq '${filter.driver2}'`;
        }
        if (!IsNullOrEmpty(filter.chassis)) {
            url += ` and ChassisLicense eq '${filter.chassis}'`;
        }
        if (!IsNullOrEmpty(filter.vessel)) {
            url += ` and LBShellID eq '${filter.vessel}'`;
        }
        url += `&sap-language=${this.sapLangCode}`;
        url += '&$format=json';
        return url;
    }

    //https://dev-api.airproducts.com/sap/equipment/v1/eVCRRecordHeaderSet?$filter=TractorLicense eq 'HU A-T3109'&$expand=eVCRRecordItemSet&$format=json
    public GenerateLastEvcrGetUrl = (filter: ITripInfo) => {
        let url = `${this.apiDomain}eVCRRecordHeaderSet?$filter=TractorLicense eq '${filter.tractor}'&$expand=eVCRRecordItemSet`
        url += `&sap-language=${this.sapLangCode}`;
        url += '&$format=json';
        return url;
    }

    public GenerateGetFiveUrl = () => {
        // https://evcr.api.tch.fuelmgmt.airproducts.com/api/values/5
        let url = `${this.apiDomain}5`
        return url;
    }

    public GenerateCounryVehiclesGetUrl = (countryCode: string) =>
    `${this.apiDomain}eVCRVehicleSet?$filter=Country eq '${countryCode}'&$format=json&sap-language=${this.sapLangCode}`
}

export const serviceAddress = new ServiceAddress(config);
