





























































import { ISelectItem } from "@/models/select-item";
import { ConsoleLog, Timeout } from "@/utility";
import Vue from "vue";
import { mdiPencil, mdiMapMarkerQuestion } from "@mdi/js";
import { RegularAnswer } from "@/models/enums";

export default Vue.extend({
  props: {
    value: String || Number,
    label: String,
    items: [],
    rules: [],
    required: Boolean,
    readonly: { type: Boolean, default: false },
    showAsLabel: { type: Boolean, default: false },
    showQuestionIcon: { type: Boolean, default: false },
    showEditIcon: { type: Boolean, default: false },
    hardStop: { type: Boolean, default: false },
  },
  data: () => ({
    selectedValue: null,
    icons: {
      mdiPencil,
      mdiMapMarkerQuestion,
    },
    lastValue: "",
  }),
  computed: {},
  methods: {
    ConsoleLog,
    setLastValue(lastValue: string) {
      ConsoleLog({ "FormRadioGroup.setLastValue": lastValue });
      this.lastValue = lastValue;
    },

    cssAnswer(itemValue: string, answer: string) {
      let cssClass = "margin-right-3 ";
      if (itemValue !== answer) {
        return cssClass;
      }
      switch (answer) {
        case RegularAnswer.Good:
          cssClass += "back-ground-green";
          break;
        case RegularAnswer.Defective:
          cssClass += "back-ground-red";
          break;
        case RegularAnswer.NA:
          cssClass += "back-ground-grey";
          break;
      }
      return cssClass;
    },
    cssLabel(itemValue: string, answer: string) {
      let cssClass = "font-size-14 margin-right-3 ";
      if (itemValue !== answer) {
        return cssClass;
      }
      switch (answer) {
        case RegularAnswer.Good:
        case RegularAnswer.Defective:
        case RegularAnswer.NA:
          cssClass += "color-white";
          break;
      }
      return cssClass;
    },

    onChanging(value: string): void {
      ConsoleLog({ "FormRegular.onChanging": value, lastValue: this.lastValue });
      this.$emit("input", value);
      this.$emit("userChange", value, this.lastValue);
    },
    // onUserChanging(value: any) {
    //   this.$emit("FormRegular.userChange", value);
    // },
    editCick(e: any) {
      // ConsoleLog({ editCick: e });
      this.$emit("userEditClick", e);
    },
    questionCick(e: any) {
      // ConsoleLog({ questionCick: e });
      this.$emit("userQuestionClick", e);
    },
  },
  mounted(): void {
    const self = this as any;
    if (self.value) {
      self.selectedValue = self.value;
      this.lastValue = self.value;
    }
    ConsoleLog({ "FormRadioGroup.lastValue": this.lastValue });
  },
});
