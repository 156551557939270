

























import { ISelectItem } from "@/models/select-item";
import { ConsoleLog, Timeout } from "@/utility";
import Vue from "vue";

export default Vue.extend({
  props: {
    value: String || Number,
    label: String,
    items: [],
    rules: [],
    required: Boolean,
    readonly: { type: Boolean, default: false },
    showAsLabel: { type: Boolean, default: false },
  },
  data: () => ({
    selectedValue: null,
  }),
  computed: {},
  methods: {
    ConsoleLog,
    onChanging(value: string): void {
      // ConsoleLog({ "FormRadioGroup.onChanging": value });
      this.$emit("input", value);
      this.$emit("userChange", value);
    },
    userChange2(value: any) {
      // ConsoleLog({ "FormRadioGroup.onUserChanging": value });
      this.$emit("userChange", value);
    },
  },
  mounted(): void {
    const self = this as any;
    if (self.value) {
      self.selectedValue = self.value;
    }
    // ConsoleLog({ "FormRadioGroup.items": self.items });
  },
});
