import { QuestionSectionType, QuestionType, RegularAnswer, SapQuestionType, SapVehcileType, SapVehcileCategory } from "@/models/enums";
import { MaintainNotification } from "@/models/maitain-notification";
import { CreateQuestionChassis, CreateQuestionTractor, CreateQuestionVessel, IQuestionFilter, IQuestionSection, QuestionSection } from "@/models/question-section";
import { Question } from "@/models/question-section/question";
import { SubSection } from "@/models/question-section/sub-section";
import * as consts from "@/models/consts"
import { ServiceCallResponse } from "@/models/service";
import { OrderedByPlantId, OrderedBySequnceId, OrderedBySubSectionId, OrderedByVehicleId } from "@/utility/sortHelper";
import { CloneObj, ConsoleLog, ConvertDateTime, DateFormatForSAP, IsNullOrEmpty, Text, TimeFormatForSAP } from "@/utility";
import { CreateTripInfo, ITripInfo, TripInfo } from "@/models/trip-info";
import { HandleSapReturnCode, GetTranslationAdditionalQues, PrePostItems, TripTypetems } from "@/utility/ui-utility";
import { GetEvcrValidation } from "../evcr-svc";
import { IVehicle, Vehicle } from "@/models/vehicle";


export function GenerateEquipmentNotifications(data: ServiceCallResponse) {
    if (!data || !data.result || data.result.length === 0) {
        return [];
    }
    return data.result.map(
        (element: any) =>
            new MaintainNotification(
                element.TractorLicense + element.ChassisLicense + element.LBShellID,
                element.NotificationTitle ?? '',
                element.NotificationID ?? '',
                element.Comments ?? '',
                element.NotificationDate ?? '',
                element.NotificationTime ?? '',
                element.NotificationStatus ?? '',
            ),
    );
}

export function GenerateQuestions(data: ServiceCallResponse, filter: IQuestionFilter) {
    ConsoleLog(['GenerateQuestions', CloneObj(data), CloneObj(filter)]);
    if (!data || !data.result || data.result.length === 0) {
        return { tractor: {} as QuestionSection, chassis: {} as QuestionSection, vessel: {} as QuestionSection };
    }
    let chassisSection = CreateQuestionChassis(false, false);;
    let vesselSection = CreateQuestionVessel(false, false);
    const tractorSection = GenerateQuestionSection(data.result, QuestionSectionType.Tractor, filter)
    if (!IsNullOrEmpty(filter.chassis)) {
        chassisSection = GenerateQuestionSection(data.result, QuestionSectionType.Chassis, filter)
    }
    if (!IsNullOrEmpty(filter.vessel)) {
        vesselSection = GenerateQuestionSection(data.result, QuestionSectionType.Vessel, filter)
    }
    //test only: set the question anser
    // SetQuestionAnswer(tractorSection);
    // SetQuestionAnswer(chassisSection);
    // SetQuestionAnswer(vesselSection);
    return { tractor: tractorSection, chassis: chassisSection, vessel: vesselSection }

    // const tractorQuestions = data.result.filter(item => { item.VehcileType === SapVehcileType.Tractor });
    // const tractorAdditionalQuestions = tractorQuestions.filter(item => { item.QuestionType === consts.SAP_QUESTION_TYPE_ADDTIONAL }).sort(OrderedBySequnceId);
    // const tractorSection = CreateQuestionTractor(tractorAdditionalQuestions.length > 0);
    // const tractorRegularQuestions = tractorQuestions.filter(item => { item.QuestionType === consts.SAP_QUESTION_TYPE_REGULAR }).sort(OrderedBySubSectionId);

    // const tractorSubSectionIndex = [... new Set(tractorQuestions.filter(item => { item.QuestionType === consts.SAP_QUESTION_TYPE_REGULAR }).map(data => data.SectionID))].sort();
    // //regular questions for tractor
    // tractorSubSectionIndex.forEach(subIndex => {
    //     let subSection = new SubSection(subIndex, false);
    //     const questions = tractorRegularQuestions.filter(item => { item.SectionID === subIndex }).sort(OrderedBySequnceId);
    //     questions.forEach(que => {
    //         subSection.questions.push(new Question(que.SequenceID, que.QuestionDesc, '', false, '', '', que.QuestionInsText, QuestionType.Regular, true, subIndex, QuestionSectionType.Tractor, "", false));
    //     });
    //     tractorSection.regularQuestionCollection.push(subSection)
    // });
    // //additional question for tractor
    // let subSectionAdditionalTractor = new SubSection(consts.STR_ADDTIONAL_QUE, false);
    // tractorAdditionalQuestions.forEach(que => {
    //     const dataType = ConvertQueTypeToFrontEnd(que.DataType);
    //     subSectionAdditionalTractor.questions.push(new Question(que.SequenceID, que.QuestionDesc, '', false, '', '', que.QuestionInsText, dataType,
    //         false, consts.STR_ADDTIONAL_QUE, QuestionSectionType.Tractor, que.DataType === SapQuestionType.Number ? `${que.LowerLimit}-${que.UpperLimit}` : "", IsBooleanQuestion(que.DataType)));
    // });
    // tractorSection.additionalQuestionCollection.push(subSectionAdditionalTractor)
}

export const SetQuestionAnswer = (section: IQuestionSection) => {
    section.regularQuestionCollection.forEach(item => {
        item.questions.forEach(que => { que.answer = RegularAnswer.NA })
    });
    section.additionalQuestionCollection.forEach(item => {
        item.questions.forEach(que => {
            if (que.questionType !== QuestionType.Number) {
                que.answer = RegularAnswer.NA;
            } else {
                que.answer = "10";
            }
        })
    });
}
export const GenerateQuestionSection = (sapQuestios: any[], sectionType: QuestionSectionType, filter: IQuestionFilter) => {
    ConsoleLog(['GenerateQuestionSection', CloneObj(sapQuestios), sectionType]);
    const vehicleType = ConvertToSAPVehicleType(sectionType)
    // const questions = sapQuestios.filter(item => item.VehcileType === vehicleType);
    let questions: any[] = [];
    switch (sectionType) {
        case QuestionSectionType.Tractor:
            questions = sapQuestios.filter(item => item.TractorLicense === filter.tractor);
            break;
        case QuestionSectionType.Chassis:
            questions = sapQuestios.filter(item => item.ChassisLicense === filter.chassis);
            break;
        case QuestionSectionType.Vessel:
            questions = sapQuestios.filter(item => item.LBShellID === filter.vessel);
            break;
    }

    const additionalQuestions = questions.filter(item => item.QuestionType === consts.SAP_QUESTION_TYPE_ADDTIONAL);//.sort(OrderedBySequnceId);
    const regularQuestions = questions.filter(item => item.QuestionType === consts.SAP_QUESTION_TYPE_REGULAR);//.sort(OrderedBySubSectionId);
    let section = CreateQuestionTractor(false);
    switch (sectionType) {
        case QuestionSectionType.Tractor:
            section = CreateQuestionTractor(additionalQuestions.length > 0);
            break;
        case QuestionSectionType.Chassis:
            section = CreateQuestionChassis(true, additionalQuestions.length > 0);
            break;
        case QuestionSectionType.Vessel:
            section = CreateQuestionVessel(true, additionalQuestions.length > 0);
            break;
    }
    section.isSelected = true;
    ConsoleLog(['GenerateQuestionSection,questions,regularQuestions,additionalQuestions,', CloneObj(questions), CloneObj(regularQuestions), CloneObj(additionalQuestions)]);
    //need to convert index from string to number in order to have the proper order.
    const subSectionIndex = [... new Set(questions.filter(item => item.QuestionType === consts.SAP_QUESTION_TYPE_REGULAR).map(data => data.SectionID))].sort();
    //regular questions 
    ConsoleLog(['GenerateQuestionSection,subSectionIndex', subSectionIndex]);
    subSectionIndex.forEach(subIndex => {
        let subSection = new SubSection(subIndex, false);
        const questions = regularQuestions.filter(item => item.SectionID === subIndex);//.sort(OrderedBySequnceId);
        questions.forEach(que => {
            subSection.questions.push(new Question(que.SequenceID, que.QuestionDesc, '', false, '', que.QuestionInsText
            , QuestionType.Regular, true, subIndex, sectionType, "", false, que.EquipmentID
            ,que.VehicleType , que.CodeGroup ?? '', que.Code, que.Charact, que.HardStop.length > 0, que.SequenceID, que.SectionID));
        });
        section.regularQuestionCollection.push(subSection)
    });
    // ConsoleLog(['GenerateQuestionSection,regularQuestionCollection,', CloneObj(section.regularQuestionCollection)]);

    //additional question 
    let subSectionAdditional = new SubSection(GetTranslationAdditionalQues(), false);
    additionalQuestions.forEach(que => {
        const dataType = ConvertQueTypeToFrontEnd(que.DataType);
        subSectionAdditional.questions.push(new Question(que.SequenceID, que.QuestionDesc, '', false, '', que.QuestionInsText, dataType,
            false, consts.STR_ADDTIONAL_QUE, sectionType, que.DataType === SapQuestionType.Number ? `${que.LowerLimit}--${que.UpperLimit}` : ""
            , IsBooleanQuestion(que.DataType), que.EquipmentID
            ,que.VehicleType, que.CodeGroup ?? '', que.Code, que.Charact, que.HardStop.length > 0, que.SequenceID, que.SectionID));
    });
    section.additionalQuestionCollection.push(subSectionAdditional)
    // ConsoleLog(['GenerateQuestionSection,additionalQuestionCollection,', CloneObj(section.additionalQuestionCollection)]);
    return section;
}

export const ConvertToSAPVehicleType = (sectionType: QuestionSectionType) => {
    let typeReturn: SapVehcileType = SapVehcileType.Tractor;;
    switch (sectionType) {
        case QuestionSectionType.Tractor:
            typeReturn = SapVehcileType.Tractor
            break;
        case QuestionSectionType.Chassis:
            typeReturn = SapVehcileType.Chassis
            break;
        case QuestionSectionType.Vessel:
            typeReturn = SapVehcileType.Vessel
            break;
    }
    ConsoleLog(['ConvertToSAPVehicleType', typeReturn]);
    return typeReturn;
}

export const ConvertQueTypeToFrontEnd = (sapQueType: string) => {
    let typeReturn: QuestionType = QuestionType.Regular;;
    switch (sapQueType) {
        case SapQuestionType.Char:
            typeReturn = QuestionType.Char
            break;
        case SapQuestionType.Boolean:
            typeReturn = QuestionType.Boolean
            break;
        case SapQuestionType.Number:
            typeReturn = QuestionType.Number
            break;
    }
    return typeReturn;
}
export const IsBooleanQuestion = (sapQueType: string) => {
    return sapQueType === SapQuestionType.Boolean ? true : false;
}

export function HandlePostResponse(data: ServiceCallResponse) {
    ConsoleLog(['HandlePostResponse', CloneObj(data)]);
    // if (!data || !data.result || data.result.length === 0) {
    //     return { tractor: {} as QuestionSection, chassis: {} as QuestionSection, vessel: {} as QuestionSection };
    // }

}

export const ValidateEvcr = async (filter: ITripInfo) => {
    const res = await GetEvcrValidation(filter)
    let msg = "";
    if (res.isSuccess === false) {
        ConsoleLog(["ValidateEvcr.isSuccess === false", res]);
        msg = HandleSapReturnCode(res.data?.error, Text('', "Encountered error when validating the eVCR"))
    }
    return msg;
}

export const HandelGettingSapReturnCode = (sapQueType: string) => {

}

export function GenerateLastEvcr(data: ServiceCallResponse, filter: ITripInfo) {
    ConsoleLog({ 'GenerateLastEvcr': CloneObj(data), "filter:": CloneObj(filter) });
    if (!data || !data.result || data.result.length === 0) {
        return { tripInfo: {} as TripInfo, questions: [] as Question[] };
    }
    const result = data.result[0] as any;
    // const dataTime = ConvertDateTime(result.TimeStamp);

    // const tripInfo = new TripInfo(result.TripType, TripTypetems(), result.LBShellTrip, result.PrePost, PrePostItems(), result.Plant,
    //     result.Driver1, result.Driver1Name, '', result.TractorLicense
    //     , result.ThirdPartyInd === consts.STR_THIRD_PARTY_TRUE ? true : false, result.ChassisLicense, result.LBShellID, result.OdoMeter, DateFormatForSAP(result.Date), TimeFormatForSAP(result.Time),"","","","","");

    // 20221125 mark third party flag of tractor chassis vessel to false
    const tripInfo = new TripInfo(result.TripType, TripTypetems(), result.LBShellTrip, result.PrePost, PrePostItems(), result.Plant, result.Plant,
        result.Driver1, result.Driver1Name, '', result.TractorLicense
        , false, '', result.ThirdPartyInd === consts.STR_THIRD_PARTY_TRUE ? true : false, result.ChassisLicense, false, '', result.LBShellID, false, '', result.OdoMeter, DateFormatForSAP(result.Date), TimeFormatForSAP(result.Time),"","","","","");

    
    const questions = GenerateQuestionsView(result.eVCRRecordItemSet.results, QuestionSectionType.Tractor, filter)
    return { tripInfo: tripInfo, questions: questions, }
}

export const GenerateQuestionsView = (sapQuestios: any[], sectionType: QuestionSectionType, filter: ITripInfo) => {
    ConsoleLog(['GenerateQuestionSection', CloneObj(sapQuestios), sectionType]);
    const questions: Question[] = [];
    ConsoleLog(['GenerateQuestionsView,sapQuestios,filter,', CloneObj(questions), CloneObj(filter)]);
    sapQuestios.forEach(que => {
        const question = new Question(que.SequenceID, que.QuestionDesc, que.Answer, que.NotificationInd, que.Comments, ''
        , QuestionType.Regular, true, "", QuestionSectionType.Tractor, "", false, que.EquipmentID
        ,que.VehicleType, que.CodeGroup ?? '', que.Code, que.Charact, que.HardStop?.length > 0, que.SequenceID, que.SectionID);
        // question.attachmentBase64 = que.Image
        questions.push(question);
    });
    return questions;
}

export function GenerateFive(data: ServiceCallResponse) {
    // ConsoleLog(['GenerateFive', CloneObj(data)]);
    if (!data) {
        return { five: null };
    }
    return { five: data.result }
}
export function GenerateVehicleList(data: ServiceCallResponse) {
    if (!data || !data.result || data.result.length === 0) {
        return [];
    }
    return data.result.map(
        (element: any) =>
            new Vehicle(
                element.VehicleID ?? '',
                element.VesselID ?? '',
                element.VehicleCat ?? '',
                element.VehicleCat2 ?? '',
                element.Country ?? '',
                element.Plant ?? '',
                element.Name1 ?? '',
            ),
    ).sort(OrderedByVehicleId);
}

export function GenerateVehicleListTest(data: ServiceCallResponse) {
    const vehicles: IVehicle[] = [];
    let country='CN';
    let plant='123';
    let name='abc';
    for (var i = 0; i < 60; i++) {
        let category = SapVehcileCategory.Tractor;
        let category2 = SapVehcileCategory.Tractor;
        if (i % 3 === 0) {
            category = SapVehcileCategory.Chassis;
            category2 = SapVehcileCategory.Vessel;
        } else if (i % 4 === 0) {
            category = SapVehcileCategory.Vessel;
            category2 = SapVehcileCategory.Chassis;
        }
        vehicles.push(new Vehicle(
            'vehicleId' + i.toString(),
            category,
            'vehicleId' + i.toString(),
            category2,
            country,
            plant,
            name
        ));
    }
    vehicles.push(new Vehicle('沪AT3109', '45Q001',SapVehcileCategory.Tractor,SapVehcileCategory.Chassis,country,plant,name));
    vehicles.push(new Vehicle('陕EU002挂', '45Q001',SapVehcileCategory.Chassis,SapVehcileCategory.Vessel,country,plant,name));
    vehicles.push(new Vehicle('45Q001', '45Q001',SapVehcileCategory.Vessel,SapVehcileCategory.Chassis,country,plant,name));
    ConsoleLog({ 'GenerateVehicleListTest': vehicles });
    return vehicles;
}


