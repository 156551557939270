
import { CreateTripInfo, CreateTripInfoTest, TripInfo } from "../trip-info";

export interface IEvcr {
    tripInfo: TripInfo;

}

export class Evcr implements IEvcr {
    tripInfo: TripInfo;
    constructor(
        tripInfo: TripInfo,) {
        this.tripInfo = tripInfo
    }
}
export function CreateEvcr() {
        return new Evcr(CreateTripInfo());
}